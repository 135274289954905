import { Button, Input, Modal, Select } from "antd";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import EmptyIcon from "../../assets/img/empty.png";
import { store } from "../../context/Store";
import GetApiCall from "../../GetApi";
import PostApiCall from "../../PostApi";
import Projections from "./Projections";

export default function CreateBatch() {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [lighterTypes, setLighterTypes] = useState([]);
  const [quantity, setQuantity] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseId, setWarehouseId] = useState(null);
  const [lotData, setLotData] = useState([]);
  const [dataDispatchTowarehouse, setDataDispatchTowarehouse] = useState([]);
  const [openWrehouseDropdownModal, setWarehouseDropdownModal] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  //  projection data
  const [projections, setProjections] = useState([]);
  const { brandList } = useContext(store);
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const getLighterTypes = () => {
    GetApiCall.getRequest("GetLighterTypes").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setLighterTypes(obj.data);
          }
        })
    );
  };
  // eslint-disable-next-line
  const filteredData = lotData.filter((data) => data?.brand_name == selectedBrand && data?.FLD_Lighter_Type == selectedType);
  const handleChangeQuantity = (id, itemId, field, value, color, lightertype) => {
    setDataDispatchTowarehouse((prevData) => {
      const existingItem = prevData.find((item) => item.id === id);
      if (existingItem) {
        return prevData.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        );
      } else {
        return [
          ...prevData,
          {
            id,
            itemId,
            color: color,
            quantity: field === "quantity" ? value : null,
            lightertype: lightertype
          },
        ];
      }
    });
  };
  const AddWarehouseItemStock = (id) => {
    Notiflix.Loading.circle();
    PostApiCall.postRequest(
      {
        jobcardid: id,
        warehoueid: warehouseId,
        stocktype: "Item",
        itemid: dataDispatchTowarehouse[0]?.itemId,
        warehouseitemquantity: totalQuantity,
        details: JSON.stringify(dataDispatchTowarehouse),
        color: dataDispatchTowarehouse[0]?.color,
        lightertype: dataDispatchTowarehouse[0]?.lightertype,
      },
      "AddWarehouseItemStock"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setWarehouseDropdownModal(false)
          getLotdata();
          getprojections();
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Cartons have been successfully generated and submitted for dispatch.")
        }
      })
    );
  };
  const getWarehouses = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetWarehouseMasterList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setWarehouses(obj.data);
          }
        })
    );
  };
  const handleOk = () => {
    // if (warehouseId != null) {
    //   setWarehouseDropdownModal(false);
    AddWarehouseItemStock();
    // } else {
    //   Notiflix.Notify.failure("Please select a warehouse to diaptch items");
    // }
  };

  const handleCancel = () => {
    setWarehouseDropdownModal(false);
  };
  const validateWarehouseDispatch = (data) => {
    const piecesPerCarton = 1000;
    const TotalQuant = data.reduce(function (acc, obj) { return acc + parseInt(obj.quantity); }, 0)
    if (TotalQuant < piecesPerCarton) {
      Notiflix.Notify.failure("Error: You must add at least 1000 pieces to proceed.")
    } else {
      if (TotalQuant % piecesPerCarton === 0) {
        setWarehouseDropdownModal(true)
      } else {
        Notiflix.Notify.failure("Error: The total quantity must be a multiple of 1000 to create full cartons.")
      }
    }
  };
  const getLotdata = () => {
    PostApiCall.postRequest({ deptid: login[0].FLD_Dept }, "GetCardLot").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            if (obj.data.length > 0) {
              setLotData(obj.data);
            }
          }
        })
    );
  };
  const getprojections = () => {
    try {
      Notiflix.Loading.dots();
      PostApiCall.postRequest({ itemid: filteredData[0]?.FLD_Item_Id }, "GetCartonProjections").then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setProjections(obj.data);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      Notiflix.Loading.remove();
    }
  };
  useEffect(() => {
    getLighterTypes();
    getWarehouses();
    getLotdata();
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    getprojections();
    // eslint-disable-next-line
  }, [selectedBrand, selectedType])
  useEffect(() => {
    const total = dataDispatchTowarehouse.reduce((sum, item) => sum + (item.quantity ? Number(item.quantity) : 0), 0);
    setTotalQuantity(total);
  }, [dataDispatchTowarehouse]);
  return (
    <>
      <Projections
        projections={projections}
        setProjections={setProjections}
      />
      <div className="mb-4 px-0">
        <div className="col-12 px-lg-2 px-0">
          <div className="default-card">
            <div className="d-flex gap-3 mb-0 mb-lg-3">
              <Select
                value={selectedBrand}
                placeholder="Choose Brand"
                onChange={(value) => setSelectedBrand(value)}
                className={"store-stock-select warehouse-select w-50"}>
                {brandList
                  .filter((brands) => brands.FLD_Enabled_Flag === "Active")
                  .map((brand) => (
                    <Select.Option
                      key={brand?.FLD_Brand_Id}
                      value={brand?.FLD_Brand_Name}>
                      {brand?.FLD_Brand_Name}
                    </Select.Option>
                  ))}
              </Select>
              <Select
                value={selectedType}
                placeholder="Choose Lighter Type"
                onChange={(value) => setSelectedType(value)}
                className={"store-stock-select warehouse-select w-50"}>
                {lighterTypes?.map((type) => (
                  <Select.Option
                    key={type?.FLD_Lighter_Type}
                    value={type?.FLD_Lighter_Type}>
                    {type?.FLD_Lighter_Type}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="col-12 mt-4 mt-lg-5">
              <div className="create-batch-box-desk">
                {filteredData.length > 0 ? filteredData?.sort((a, b) => b.FLD_Available_Quantity - a.FLD_Available_Quantity).map((lot) => {
                  return (
                    <div className="create-batch-desk-card">
                      <div>
                        <label>Item Name</label>
                        <p>{lot?.brand_name}-{lot?.item_name}</p>
                      </div>
                      <div>
                        <label>Color</label>
                        <div
                          className="jobcard-color-span"
                          style={{ background: `${lot?.FLD_Color}` }}
                        />
                      </div>
                      <div>
                        <label>Available Stock</label>
                        <p>{lot?.FLD_Available_Quantity}</p>
                      </div>
                      <div>
                        <label>Quantity</label>
                        <Input
                          readOnly={login[0].FLD_Dept == 6 ? false : true}
                          size="sm"
                          allowClear
                          className="input-box"
                          name="quantity"
                          // {...register("allotedby", {
                          //   required: "Alloted by is required",
                          // })}
                          onChange={(e) => {
                            setQuantity(e.target.value[lot?.FLD_Id])
                            handleChangeQuantity(lot?.FLD_Id, lot?.FLD_Item_Id, 'quantity', e.target.value, lot?.FLD_Color, lot?.FLD_Lighter_Type)
                          }}
                          value={quantity}
                        />
                        {/* {errors.allotedby && (
                                  <p className="text-danger">{errors.allotedby.message}</p>
                                )} */}
                      </div>
                    </div>
                  )
                }) :
                  ((selectedBrand != null || selectedType != null) && <div className="h-100 w-100 my-5 py-5 d-flex align-items-center flex-column justify-content-center">
                    <img
                      src={EmptyIcon}
                      className="img-fluid w-20"
                      alt="Empty Icon"
                    />
                    <p>No items available for the selected brand and lighter type. Please try selecting a different combination or check back later.</p>
                  </div>)
                }
                {selectedBrand == null && selectedType == null && (
                  <div className="h-100 w-100 my-5 py-5 d-flex align-items-center flex-column justify-content-center">
                    <img
                      src={EmptyIcon}
                      className="img-fluid w-20"
                      alt="Empty Icon"
                    />
                    <p>Make a selection for both brand and lighter type to continue.</p>
                  </div>
                )}
              </div>
              <div className="sticky-bottom text-end bg-white py-3 d-lg-flex justify-content-between">
                {totalQuantity > 0 ? (
                  <>
                    <h6>Total Lighters - {totalQuantity}</h6>
                    <Button disabled={login[0].FLD_Dept == 6 ? false : true} type="button" onClick={() => { validateWarehouseDispatch(dataDispatchTowarehouse) }} className="default-btn border-0">Select Warehouse & Continue</Button>
                  </>) : (login[0].FLD_Dept == 6 ? <p className="text-center w-100 text-warning">Please Enter Quantity</p> : <p className="text-center w-100 text-warning">You are not an authorized person to fill the above details</p>)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Select Warehouse"
        open={openWrehouseDropdownModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        zIndex={1000}>
        <Select
          size="middle"
          className="input-box w-100 my-3"
          placeholder="Choose a Warehouse"
          onChange={(value) => setWarehouseId(value)}>
          {warehouses.map((warehouse) => (
            <Select.Option
              key={warehouse.FLD_Warehouse_Id}
              value={warehouse.FLD_Warehouse_Id}>
              {warehouse.FLD_Warehouse_Name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}
