import { Tooltip } from "antd";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { FaFileCircleXmark } from "react-icons/fa6";
import { Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./SideNavbar.css";
export default function RejectionSidebar({ handleCollapse }) {
  return (
    <>
      <Menu>
        <Link
          to={"/rejection-master-dashboard"}
          onClick={() => handleCollapse()}
        >
          <MenuItem className="sidebar-menuitem">
            <Tooltip
              title="Dashboard"
              placement="topLeft"
              overlayClassName="custom-tooltip">
              <BsFillHouseDoorFill className="sidebar-icon" /> Dashboard
            </Tooltip>
          </MenuItem>
        </Link>
        <Link to={"/rejections"} onClick={() => handleCollapse()}>
          <MenuItem className="sidebar-menuitem">
            <Tooltip
              title="Rejection Management"
              placement="topLeft"
              overlayClassName="custom-tooltip">
              <FaFileCircleXmark className="sidebar-icon" /> Rejection Management
            </Tooltip>
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
}
