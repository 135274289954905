import { Input, Select, Table } from "antd";
import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import NoImg from "../../assets/img/no-img.jpg";
import PostApiCall from "../../PostApi";
import moment from "moment";
import GetApiCall from "../../GetApi";
import { FaBox, FaBoxes, FaEye, FaUser } from "react-icons/fa";
import { TbLighter } from "react-icons/tb";
import { IoIosColorPalette } from "react-icons/io";
import { IoCalendar } from "react-icons/io5";

export default function DispatchedOrders() {
  const [dispatchedItems, setDisptatchedItems] = useState([]);
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [showCanvas, setShowCanvas] = useState(false);
  const [selectedOrderData, setSelectedOrderData] = useState({});
  const [orderItemDetail, setOrderItemDetail] = useState([]);

  useEffect(() => {
    getitemList();
    getCustomerList();
  }, []);

  const handleShowCanvas = (data) => {
    setSelectedOrderData(data);
    setOrderItemDetail(JSON?.parse(data?.items_details));

    setShowCanvas(true);
  };
  const handleClose = () => {
    setShowCanvas(false);
  };

  const getitemList = () => {
    GetApiCall.getRequest("GetDispatchedItems").then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setDisptatchedItems(obj.data);
          setFilteredOrder(obj.data);
        }
      })
    );
  };
  const getCustomerList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetCustomerList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setCustomerList(obj.data);
          }
        })
    );
  };

  const searchInOrders = (value) => {
    const searchedOrder = dispatchedItems.filter((orders) => orders?.Customer_Name?.toLowerCase().includes(value?.toLowerCase()))
    setFilteredOrder(searchedOrder)
  };

  const screenSize = window.innerWidth;
  const columns = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Total Items",
      dataIndex: "totalitems",
      key: "totalitems",
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
    },
    {
      title: "Action",
      dataIndex: "view",
      key: "view",
    },
  ];
  const dispatchedOrderRow = filteredOrder.map((items, index) => {
    return {
      key: index + 1,
      serial: index + 1,
      customerName: items?.Customer_Name,
      // quantity:items?.FLD_Order_Quantity,
      totalitems: `${items?.items_count} ${items?.items_count === 1 ? "Item" : "Items"}`,
      orderDate: moment(items?.FLD_Order_Date).format("DD-MM-YYYY"),
      view: (
        <span className="default-btn" onClick={() => handleShowCanvas(items)}>
          <FaEye className="mb-1" /> {screenSize > 568 ? "" : "View"}
        </span>
      ),
    };
  });

  return (
    <div className="margin-left job-card-list-table">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Dispatched Orders</h4>
              </div>
              <div className="row">
                <div className="col-12 pb-3">
                  <Input placeholder="Search customer" onChange={(e) => searchInOrders(e.target.value)} />
                </div>

                <div className="col-12">
                  {screenSize > 568 ? (
                    <Table
                      bordered
                      className="operation-expense-table-size spare-request-table dispatched-order-table"
                      columns={columns}
                      dataSource={dispatchedOrderRow}
                    />
                  ) : (
                    <div className="supervisor-dashboard spare-card-list-page job-card-page">
                      <div className="job-card-main-box spare-card-list mb-2 mobile-stock-card-box mobile-dispatched-card">
                        {dispatchedOrderRow?.map((items, index) => {
                          return (
                            <div
                              key={index}
                              className="job-card-box store-stcok-mcard d-flex flex-wrap">
                              <div className="box w-100 pb-3">
                                <FaUser />

                                <h6 className="mb-0">{items?.customerName}</h6>
                              </div>
                              <div className="box w-100">
                                <p className="mb-0 fs-6">
                                  <IoCalendar />{" "}
                                  {moment(items?.FLD_Order_Date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="box w-100 justify-content-between pb-0">
                                <p className="w-50 mb-0 fs-6">
                                  <FaBox /> {items?.totalitems}
                                </p>
                                <p className="w-50 mb-0 justify-content-end fs-6">
                                  {items?.view}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        placement="end"
        size="sm"
        show={showCanvas}
        onHide={handleClose}
        className="view-added-spares-modal dispatched-order-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Dispatched Order Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="border p-3">
            {orderItemDetail?.map((items, index) => (
              <div key={index}>
                <img
                  src={items?.FLD_Item_Image || NoImg}
                  className="img-fluid w-100 mb-3 "
                  alt={items?.FLD_Item_Name}
                />
                <p className="border-bottom pb-2 mb-2">
                  Item Name - {items?.FLD_Item_Name}
                </p>
                <p className="border-bottom pb-2 mb-2">
                  Order Quantity - {items?.FLD_Order_Quantity}
                </p>
              </div>
            ))}
            <div>
              <p className="border-bottom pb-2 mb-2">
                {" "}
                Customer Name - {selectedOrderData?.Customer_Name}
              </p>
              <p className="border-bottom pb-2 mb-2">
                {" "}
                Order Date -{" "}
                {moment(selectedOrderData?.FLD_Order_Date).format("DD/MM/YYYY")}
              </p>
              <p className="mb-0">
                {" "}
                Total Items - {selectedOrderData?.items_count}
              </p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
