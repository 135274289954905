import {
  Collapse,
  DatePicker, Input,
  Modal,
  notification,
  Select,
  Statistic,
  Table
} from "antd";
import moment from "moment";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { IoIosWarning, IoMdRefresh } from "react-icons/io";

import { IoCalendar, IoFilter } from "react-icons/io5";
import { MdEdit, MdWarning } from "react-icons/md";
import EmptyIcon from "../../assets/img/empty.png";
import SaveIcon from "../../assets/img/save.png";
import { store } from "../../context/Store";
import PostApiCall from "../../PostApi";
import CreateBatch from "./CreateBatch";
import "./ProductionManagement.css";
const { RangePicker } = DatePicker;
const { Countdown } = Statistic;
const { Panel } = Collapse;
export function JobCardAccordion() {
  const [jobCardData, setJobCartData] = useState([]);
  const [lighterTypes, setLighterTypes] = useState([]);
  const [filteredCardData, setFilteredCardData] = useState([]);
  const [deadstock, setDeadStock] = useState(null);
  const [repairable, setRepairable] = useState(null);
  const [isCritical, setIsCritical] = useState(false);
  const [deptDropdown, setDeptDropdown] = useState([]);
  const { brandList } = useContext(store);

  const [viewPDFModal, setViewPDFModal] = useState(false);

  const [showAcceptConfirm, setShowAcceptConfirm] = useState(false);

  const [jobCardDatatoUpdate, setJobCardDatatoUpdate] = useState({});
  var login = JSON.parse(localStorage.getItem("LoginDetail"));

  // states to update stage details
  const [isStageEdit, setIsStageEdit] = useState(false);
  const [updQtyIn, setUpdQtyIn] = useState(null);
  const [updDeadStk, setUpdDeadStk] = useState(null);
  const [updRepairable, setUpdRepairable] = useState(null);
  const [updQtyOut, setUpdQtyOut] = useState(null);

  // states for date filter
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const [showFilters, setShowFilters] = useState(false);

  // stage dropdown selected option
  const [selectedOption, setSelectedOption] = useState("Pending");

  const handleCloseFilters = () => {
    setShowFilters(false);
  };

  // jobcardData to download
  const [mappedJobCardData, setMappedJobCardData] = useState([]);
  const [selectedJobCard, setSelectedJobCard] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line
    getJobCards();
    // eslint-disable-next-line
    getDepartmentData();
    // eslint-disable-next-line
  }, []);


  const getDepartmentData = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetDepartment").then(
      (results) =>
        results.json().then((obj) => {
          // dept data is same for everyone
          if (results.status === 200 || results.status === 201) {
            const deptData = obj.data.filter(
              // eslint-disable-next-line
              (dept) => dept.FLD_Enabled_Flag == "Active"
            );
            deptData.push(
              {
                FLD_Department_Id: 11,
                Fld_Sequence: 0,
                FLD_Department_Name: "Pending",
              },
              {
                FLD_Department_Id: 9,
                Fld_Sequence: 9,
                FLD_Department_Name: "List All",
              },
              {
                FLD_Department_Id: 10,
                Fld_Sequence: 8,
                FLD_Department_Name: "Completed",
              }
            );
            setDeptDropdown(deptData);
          }
        })
    );
  };

  const getJobCards = () => {
    try {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          wherecaluse: "",
        },
        "GetActiveJobCards"
      ).then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setJobCartData(
              obj.data.sort((a, b) => b.FLD_Job_Card_Id - a.FLD_Job_Card_Id)
            );
            const uniqueLighterTypes = [
              ...new Map(
                obj.data.map((item) => [item.FLD_Lighter_Type, item])
              ).values(),
            ];
            setLighterTypes(uniqueLighterTypes);
            setSelectedOption(
              // eslint-disable-next-line
              login[0].FLD_User_Name != "Admin"
                ? login[0].FLD_User_Name
                : "Pending"
            );
            setFilteredCardData(
              // eslint-disable-next-line
              login[0]?.FLD_Name != "Admin"
                ? //  && login[0]?.FLD_Name != "Gas Filling"
                obj.data
                  .sort((a, b) => b.FLD_Job_Card_Id - a.FLD_Job_Card_Id)
                  .filter(
                    // eslint-disable-next-line
                    (card) => card?.active_stage == login[0]?.FLD_User_Name
                  )
                : obj.data
                  .sort((a, b) => b.FLD_Job_Card_Id - a.FLD_Job_Card_Id)
                  .filter((jobcard) => jobcard.active_stage !== "Completed") // show all cards to admin
            );
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
      Notiflix.Notify.failure("Something went wrong");
      Notiflix.Loading.remove();
    }
  };

  const UpdateJobCardApprovalHistory = (
    jobCardId,
    cardApprovalId,
    criticalStatus
  ) => {
    PostApiCall.postRequest(
      {
        jobcardid: jobCardId,
        jobcardapprovalid: cardApprovalId,
        status: "Accepted",
        criticalStatus: criticalStatus,
      },
      "UpdateJobCardApprovalHistory"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          getJobCards(); // get updated card status
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };

  const onHideAcceptConfirm = () => {
    setShowAcceptConfirm(false);
  };

  // function to update the count at every stage
  const UpdateJobCardStage = () => {
    Notiflix.Loading.dots("");
    PostApiCall.postRequest(
      {
        jobcardid: jobCardDatatoUpdate?.jobcardId,
        actionby: login[0].FLD_Dept,
        department: jobCardDatatoUpdate?.jobcardstage,
        deadstock: deadstock,
        repairable: repairable,
        action: "closed",
        comments: "",
      },
      "UpdateJobCardStage"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Job card updated successfully!", {
            position: "center-bottom",
          });
          setDeadStock(null);
          setRepairable(null);
          setShowAcceptConfirm(false);
          getJobCards(); // get updated card status
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };

  const calculateCountdownTime = (actionTime) => {
    if (!actionTime) {
      return 0; // Return 0 if actionTime is null, undefined, or invalid
    }
    try {
      const actionDate = new Date(actionTime);
      if (isNaN(actionDate.getTime())) {
        return 0; // Return 0 if the date is invalid
      }
      const endTime = new Date(actionDate.getTime() + 30 * 60 * 1000); // Add 6 hours
      const currentTime = Date.now(); // Current time
      const remainingTime = endTime.getTime() - currentTime; // Remaining time
      return remainingTime > 0 ? remainingTime : 0; // Prevent negative time
    } catch (error) {
      console.error("Error calculating countdown time:", error);
      return 0; // Return 0 in case of an error
    }
  };
  const handleChange = (value) => {
    setSelectedOption(value);
    setFromDate(null);
    if (value === "Pending") {
      setFilteredCardData(
        jobCardData.filter((card) => card?.active_stage !== "Completed")
      );
    }
    // eslint-disable-next-line
    if (value != "List All" && value !== "Pending") {
      setFilteredCardData(
        // eslint-disable-next-line
        jobCardData.filter((card) => card?.active_stage == value)
      );
      // eslint-disable-next-line
    } else if (value == "List All") {
      setFilteredCardData(jobCardData);
    }
  };

  // function to update job stage details
  const updateJobStageDetails = (jobCardId, jobcardapprovalid, deptId) => {
    // eslint-disable-next-line
    if (updDeadStk == "" || updDeadStk == null || updDeadStk < 0) {
      return Notiflix.Notify.failure("Something went wrong");
    }
    // eslint-disable-next-line
    if (updRepairable == "" || updRepairable == null || updRepairable < 0) {
      return Notiflix.Notify.failure("Something went wrong");
    }
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        jobcardid: jobCardId,
        updatedby: login[0].FLD_User_Id,
        deptid: deptId,
        deadstock: updDeadStk,
        repairable: updRepairable,
        stageid: jobcardapprovalid,
      },
      "UpdateJobCardApprovalData"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Job card updated successfully!", {
            position: "center-bottom",
          });
          setIsStageEdit(false);
          getJobCards();
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };
  // filter job cards between specific dates
  const filterByDate = () => {
    const selectedData = jobCardData.filter(
      // eslint-disable-next-line
      (card) => card?.active_stage == selectedOption
    );
    // eslint-disable-next-line
    if (fromDate == null || toDate == null) {
      return Notiflix.Notify.failure(
        "Please fill in both 'From' and 'To' dates."
      );
    }
    // eslint-disable-next-line
    if (selectedOption == "List All") {
      const filteredArray = jobCardData.filter((item) => {
        const itemDate = moment(item.FLD_Current_Action_Time).format(
          "YYYY-MM-DD"
        );
        return itemDate >= fromDate && itemDate <= toDate;
      });
      setFilteredCardData(filteredArray);
    } else {
      const filteredArray = selectedData.filter((item) => {
        const itemDate = moment(item.FLD_Current_Action_Time).format(
          "YYYY-MM-DD"
        );
        return itemDate >= fromDate && itemDate <= toDate;
      });
      setFilteredCardData(filteredArray);
    }
  };
  const JobCardStageCols = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Quantity In",
      dataIndex: "quantityIn",
      key: "quantityIn",
    },
    {
      title: "Dead Stock",
      dataIndex: "deadStock",
      key: "deadStock",
    },
    {
      title: "Repairable",
      dataIndex: "repairable",
      key: "repairable",
    },
    {
      title: "Quantity Out",
      dataIndex: "quantityOut",
      key: "quantityOut",
    },
    {
      title: "Accepted On",
      dataIndex: "intiming",
      key: "intiming",
    },
    {
      title: "Time Out",
      dataIndex: "outtiming",
      key: "outtiming",
    },
  ];
  // let mappedJobCardData = []

  const downloadPdfData = (jobcardId) => {
    Notiflix.Loading.dots("");
    const selectedJobCard = jobCardData.filter(
      (jobcard) => jobcard.FLD_Job_Card_Id === jobcardId
    );
    setSelectedJobCard(selectedJobCard);

    const jobCardDataRow = jobCardData
      .filter((jobcard) => jobcard.FLD_Job_Card_Id === jobcardId)
      .map((jobcard, index) => {
        const stageData = JSON.parse(jobcard.jobcard_stages)?.stages;

        return stageData?.map((stages, stageIndex) => ({
          key: `${stageIndex + 1}`,
          serial: `${stageIndex + 1}`,
          stage: stages?.fld_pending_with,
          quantityIn: stages?.FLD_Item_Qty_In,
          deadStock: stages?.FLD_Dead_Stock_Qty_In,
          repairable: stages?.FLD_Repairable_Qty,
          quantityOut: stages?.FLD_Item_Qty_Out,
          intiming: moment(stages?.FLD_Accept_Date_Time).format(
            "DD/MM/YYYY HH:mm a"
          ),
          outtiming: moment(stages?.fld_action_date).format(
            "DD/MM/YYYY HH:mm a"
          ),
        }));
      })
      .flat();

    setMappedJobCardData(jobCardDataRow);
    setTimeout(() => {
      downloadPdfI(selectedJobCard[0]?.FLD_Job_Card_Number);
    }, 10);
  };
  const downloadPdfI = async (jobcardNumber) => {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("downloaddata");
    var opt = {
      margin: 1,
      filename: jobcardNumber,
      image: { type: "png", quality: 1.9 },
      html2canvas: {
        scale: 1.1,
        useCORS: true,
        allowTaint: true,
        proxy: null,
        dpi: 192,
      },
      pagebreak: { mode: "css" },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        compress: true,
        auto: true,
      },
    };
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        setViewPDFModal(false);
        Notiflix.Loading.remove();
      });
  };

  return (
    <>
      <div className="margin-left blank-section job-card-accordion">
        <div className="container-fluid pt-4 pb-2 ">
          <div className="row pt-4 mt-4 mb-4 ">
            {/* Department dropdown */}
            {/* {(login[0].FLD_User_Name !== "Tray Shifting") && */}
            <div className="d-lg-flex justify-content-lg-end gap-4 px-0">
              <div className="d-flex justify-content-end gap-3">
                <div className="mb-2 d-flex me-lg-2 justify-content-end gap-3">
                  <Button
                    onClick={() => setShowFilters(true)}
                    className="default-btn border-0 px-2 fw-medium d-flex align-items-center">
                    <IoFilter className="fs-5 ms-1 me-2" /> Filter By
                  </Button>
                </div>
              </div>
            </div>
            {/* } */}
            {/* Hide Job Cards for Tray Shifting */}
            {(selectedOption !== "Tray Shifting") ? (
              <div className="col-12 mb-4 px-0 px-lg-2">
                <Collapse accordion>
                  {filteredCardData
                    .filter(
                      (jobcard) =>
                        selectedBrand === null ||
                        jobcard?.FLD_Brand_Name === selectedBrand
                    )
                    ?.filter(
                      (jobcard) =>
                        selectedType === null ||
                        jobcard?.FLD_Lighter_Type === selectedType
                    )
                    .map((job, jobIndex) => {
                      // Get stages data from every jobcard
                      const stages = JSON.parse(job.jobcard_stages)?.stages || [];
                      const currentStageSeq = stages.filter(
                        // eslint-disable-next-line
                        (itemdet) => itemdet.fld_pending_with == job.active_stage
                      );
                      const activeStageSequence =
                        currentStageSeq[0]?.fld_sequence;
                      const { active_stage, FLD_Current_Action_Time } = job;
                      let countdownTime = 0;
                      // calculate countdown time only for FT1 & FT2
                      if (
                        active_stage === "Flame Testing 1" ||
                        active_stage === "Flame Testing 2"
                      ) {
                        countdownTime = calculateCountdownTime(
                          FLD_Current_Action_Time
                        );
                      }
                      const criticalStage = stages.find(
                        (stage) =>
                          stage.fld_pending_with === active_stage &&
                          stage.FLD_IsCritical === "Yes"
                      );
                      if (
                        criticalStage &&
                        criticalStage.FLD_IsCritical === "Yes" &&
                        !isCritical
                      ) {
                        setIsCritical(true);
                      }
                      return (
                        <Panel
                          // eslint-disable-next-line
                          className={`${login[0]?.FLD_Dept == 6 ? "tray-checkbox-add" : ""}`}
                          key={jobIndex}
                          header={
                            <>
                              <div className="d-lg-flex justify-content-between px-3 py-2">
                                <p className="card-number">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={job?.item_image}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    {job.FLD_Job_Card_Number}
                                  </div>
                                  <span
                                    style={{
                                      display: stages.some(
                                        (stage) =>
                                          stage.fld_pending_with ===
                                          active_stage &&
                                          stage.FLD_IsCritical === "Yes"
                                      )
                                        ? "flex"
                                        : "none",
                                    }}
                                    className="align-items-center gap-2">
                                    <MdWarning className="text-warning" />
                                    <p>
                                      This job card was accepted at {active_stage}{" "}
                                      before 6 hours
                                    </p>
                                  </span>
                                </p>
                              </div>
                              <div className="mt-2 border-top pt-2 px-3 inner-accordion-header">
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="w-100">
                                    <span>Total Quantity : </span>
                                    {job.FLD_Item_Qty}
                                  </p>
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent Collapse from handling this click
                                      setViewPDFModal(true);
                                      downloadPdfData(job?.FLD_Job_Card_Id);
                                    }}
                                    className="text-theme download-pdf-btn d-flex align-items-center gap-1">
                                    Download <FiDownload />
                                  </div>
                                </div>
                                <p>
                                  <span>Current Quantity : </span>
                                  {job.FLD_Current_Quantity}
                                </p>
                                <p>
                                  <span>Stage : </span>
                                  {job.active_stage || "Flame Test 1"}
                                </p>
                                {/* <div className="mt-4 lg-mt-0 d-flex justify-content-end gap-2">
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent Collapse from handling this click
                                    setViewPDFModal(true);
                                    downloadPdfData(job?.FLD_Job_Card_Id);
                                  }}
                                  className="align-items-center gap-2 default-btn border-0"
                                >
                                  <HiDocumentDownload />
                                </button> */}
                                {/* <button
                                  type="button"
                                  onClick={() => { shareJobCardDetails(job?.FLD_Job_Card_Id) }}
                                  className="align-items-center gap-2 default-btn border-0"
                                >
                                  Share
                                </button> */}

                                {/* </div> */}

                                <Countdown
                                  style={{
                                    display:
                                      countdownTime > 0 &&
                                        (active_stage === "Flame Testing 1" ||
                                          active_stage === "Flame Testing 2") &&
                                        isCritical
                                        ? "flex"
                                        : "none",
                                  }}
                                  title="This Batch is currently on hold for the remaining duration"
                                  value={Date.now() + countdownTime} // Set the deadline dynamically
                                  className="countdown w-100 justify-content-lg-end"
                                  onFinish={() =>
                                    notification.success({
                                      message: "Countdown Completed!",
                                    })
                                  }
                                  format="HH:mm:ss"
                                />
                              </div>
                            </>
                          }>
                          <Collapse className="inner-collapse" accordion>
                            {stages.map((stage, stageIndex) => {
                              var editabeStage;
                              if (
                                stage.fld_sequence ===
                                activeStageSequence - 1
                              ) {
                                editabeStage = true;
                              }
                              return stage.fld_action === "closed" ? (
                                <Panel
                                  key={stageIndex}
                                  header={
                                    <div className=" px-3 py-2 mt-1 jobcard-stage-title">
                                      <p>
                                        {stage.fld_pending_with}
                                        <div>
                                          Batch out on:
                                          <span
                                            className={`${stage.FLD_IsCritical === "Yes"
                                              ? "text-danger"
                                              : ""
                                              }`}>
                                            <FaCalendarAlt
                                              className={`${stage.FLD_IsCritical === "Yes"
                                                ? "text-danger"
                                                : ""
                                                }`}
                                            />{" "}
                                            {moment(
                                              stage?.fld_action_date
                                            ).format("DD/MM/YYYY")}
                                          </span>
                                          <span
                                            className={`${stage.FLD_IsCritical === "Yes"
                                              ? "text-danger"
                                              : ""
                                              }`}>
                                            <FaClock
                                              className={`${stage.FLD_IsCritical === "Yes"
                                                ? "text-danger"
                                                : ""
                                                }`}
                                            />{" "}
                                            {moment(
                                              stage?.fld_action_date
                                            ).format("HH:mm a")}
                                          </span>
                                        </div>
                                      </p>
                                    </div>
                                  }>
                                  <div className="stage-card">
                                    <div className="d-flex justify-content-between ">
                                      <span className="top-text">
                                        The details of the batch of lighters
                                        processed at this stage are as follows:
                                      </span>
                                      {isStageEdit === false &&
                                        editabeStage === true &&
                                        login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        ) && (
                                          <Button
                                            className="edit-stage-btn border-0 py-2 d-flex align-items-center"
                                            onClick={() => {
                                              setIsStageEdit(true);
                                              setUpdQtyIn(stage.FLD_Item_Qty_In);
                                              setUpdDeadStk(
                                                stage.FLD_Dead_Stock_Qty_In
                                              );
                                              setUpdRepairable(
                                                stage.FLD_Repairable_Qty
                                              );
                                              setUpdQtyOut(
                                                stage.FLD_Item_Qty_Out
                                              );
                                            }}>
                                            <MdEdit />
                                          </Button>
                                        )}
                                    </div>
                                    <div className="stage-content">
                                      <div className="box">
                                        <span>Quantity In </span>
                                        {isStageEdit === true ? (
                                          <Input
                                            disabled={true}
                                            allowClear
                                            className="input-box w-50"
                                            value={updQtyIn}
                                            onChange={(e) =>
                                              setUpdQtyIn(e.target.value)
                                            }
                                          />
                                        ) : (
                                          <p>{stage.FLD_Item_Qty_In}</p>
                                        )}
                                      </div>
                                      <div className="box">
                                        <span>Dead Stock :</span>
                                        {isStageEdit === true ? (
                                          <Input
                                            disabled={
                                              !login[0].FLD_Access_Dept.includes(
                                                stage.Fld_Department_Id
                                              )
                                            }
                                            allowClear
                                            className="input-box w-50"
                                            value={updDeadStk}
                                            onChange={(e) =>
                                              setUpdDeadStk(e.target.value)
                                            }
                                          />
                                        ) : (
                                          <p>{stage.FLD_Dead_Stock_Qty_In}</p>
                                        )}
                                      </div>
                                      <div className="box border-0">
                                        <span>Repairable :</span>
                                        {isStageEdit === true ? (
                                          <Input
                                            disabled={
                                              !login[0].FLD_Access_Dept.includes(
                                                stage.Fld_Department_Id
                                              )
                                            }
                                            allowClear
                                            className="input-box w-50"
                                            value={updRepairable}
                                            onChange={(e) =>
                                              setUpdRepairable(e.target.value)
                                            }
                                          />
                                        ) : (
                                          <p>{stage.FLD_Repairable_Qty}</p>
                                        )}
                                      </div>
                                      <div className="box border-0">
                                        <span>Quantity Out</span>
                                        <span>:</span>
                                        {isStageEdit === true ? (
                                          <Input
                                            allowClear
                                            className="input-box w-50"
                                            disabled={true}
                                            value={updQtyOut}
                                            onChange={(e) =>
                                              setUpdQtyOut(e.target.value)
                                            }
                                          />
                                        ) : (
                                          <p>{stage.FLD_Item_Qty_Out}</p>
                                        )}
                                      </div>
                                    </div>
                                    {isStageEdit === true &&
                                      editabeStage === true && (
                                        <div className="d-flex justify-content-end gap-2">
                                          <Button
                                            className="default-btn border-0 mt-3"
                                            onClick={() =>
                                              updateJobStageDetails(
                                                job.FLD_Job_Card_Id,
                                                stage.FLD_Job_Card_Approval_Id,
                                                stage.Fld_Department_Id
                                              )
                                            }>
                                            {" "}
                                            Update
                                          </Button>
                                          <Button
                                            className="default-btn border-0 mt-3"
                                            onClick={() => setIsStageEdit(false)}>
                                            Cancel
                                          </Button>
                                        </div>
                                      )}
                                  </div>
                                </Panel>
                              ) : (
                                <Panel
                                  key={stageIndex}
                                  header={
                                    <div className="px-3 py-2 mt-1 d-flex">
                                      <p>{stage.fld_pending_with}</p>
                                      <span
                                        className="ms-2"
                                        style={{
                                          display:
                                            stage.FLD_IsCritical === "Yes"
                                              ? "block"
                                              : "none",
                                        }}>
                                        <MdWarning className="text-warning" />
                                      </span>
                                    </div>
                                  }>
                                  <div className="stage-card">
                                    {(stage?.fld_status === "Open" ||
                                      stage.fld_status === "") && (
                                        <div className="accepte-box">
                                          {stage.FLD_Hold_Time !== "00:00:00" &&
                                            countdownTime > 0 && (
                                              <>
                                                <p className="fs-6">
                                                  <IoIosWarning className="text-warning" />{" "}
                                                  CAUTION: TIMING CRITICAL{" "}
                                                  <IoIosWarning className="text-warning" />
                                                </p>
                                                <span>
                                                  This batch of lighters must not
                                                  move to the next stage until the
                                                  remaining time on the 6-hour
                                                  timestamp expires.
                                                </span>
                                              </>
                                            )}

                                          {stage.fld_status === "" && (
                                            <>
                                              <span>
                                                Access is restricted until all the
                                                above stages are completed and
                                                accepted.
                                              </span>
                                            </>
                                          )}
                                          {stage.fld_status === "Open" &&
                                            login[0].FLD_Access_Dept.includes(
                                              stage.Fld_Department_Id
                                            ) && (
                                              <>
                                                {login[0].FLD_User_Name ===
                                                  "Admin" &&
                                                  stage?.fld_pending_with ===
                                                  "Tray Shifting" ? (
                                                  ""
                                                ) : (
                                                  <>
                                                    {countdownTime <= 0 && (
                                                      <span>
                                                        Are you sure you want to
                                                        accept the batch into the{" "}
                                                        {stage.fld_pending_with}{" "}
                                                        stage now?
                                                      </span>
                                                    )}
                                                    <span
                                                      className="default-btn"
                                                      onClick={() => {
                                                        let criticalStatus;
                                                        if (countdownTime > 0) {
                                                          criticalStatus = "Yes";
                                                        } else {
                                                          criticalStatus = "No";
                                                        }
                                                        UpdateJobCardApprovalHistory(
                                                          job.FLD_Job_Card_Id,
                                                          stage.FLD_Job_Card_Approval_Id,
                                                          criticalStatus
                                                        );
                                                      }}>
                                                      Accept
                                                    </span>
                                                  </>
                                                )}
                                              </>
                                            )}
                                        </div>
                                      )}
                                    <span className="top-text">
                                      Enter the details of batch of lighters
                                      processed at {stage.fld_pending_with},
                                      including deadstock & repairable quantity.
                                    </span>
                                    <div className="stage-input">
                                      <div className="box">
                                        <span>Quantity In :</span>
                                        <p>{stage.FLD_Item_Qty_In}</p>
                                      </div>
                                      <div className="box">
                                        <span>Dead Stock</span>
                                        <Input
                                          disabled={
                                            !login[0].FLD_Access_Dept.includes(
                                              stage.Fld_Department_Id
                                            )
                                          }
                                          allowClear
                                          className="input-box"
                                          value={deadstock}
                                          onChange={(e) =>
                                            setDeadStock(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="box">
                                        <span>Repairable</span>
                                        <Input
                                          disabled={
                                            !login[0].FLD_Access_Dept.includes(
                                              stage.Fld_Department_Id
                                            )
                                          }
                                          allowClear
                                          className="input-box"
                                          value={repairable}
                                          onChange={(e) =>
                                            setRepairable(e.target.value)
                                          }
                                        />
                                      </div>
                                      {/* <div className="box border-0">
                                    <span>Quantity Out</span>
                                    <Input
                                      disabled={
                                        !login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                      }
                                      allowClear
                                      className="input-box"
                                      value={qtyOut}
                                      onChange={(e) =>
                                        setQtyOut(e.target.value)
                                      }
                                    />
                                  </div> */}
                                      <div
                                        className="mt-3 text-end"
                                        style={{
                                          display:
                                            login[0].FLD_Access_Dept.includes(
                                              stage.Fld_Department_Id
                                            )
                                              ? "block"
                                              : "none",
                                        }}>
                                        {login[0].FLD_User_Name !== "Admin" ||
                                          stage?.fld_pending_with !==
                                          "Tray Shifting" ? (
                                          <>
                                            {
                                              login[0].FLD_User_Name !==
                                                "Tray Shifting" ? (
                                                <span
                                                  className="default-btn"
                                                  onClick={() => {
                                                    if (
                                                      // eslint-disable-next-line
                                                      deadstock != null &&
                                                      // eslint-disable-next-line
                                                      deadstock != "" &&
                                                      // eslint-disable-next-line
                                                      repairable != "" &&
                                                      // eslint-disable-next-line
                                                      repairable != null
                                                    ) {
                                                      setShowAcceptConfirm(true);
                                                    }
                                                    setJobCardDatatoUpdate({
                                                      jobcardId:
                                                        job.FLD_Job_Card_Id,
                                                      jobcardstage:
                                                        stage.Fld_Department_Id,
                                                      job: "job",
                                                    });
                                                  }}>
                                                  Save
                                                </span>
                                              ) : null}
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </Panel>
                              );
                            })}
                          </Collapse>
                        </Panel>
                      );
                    })}
                </Collapse>
              </div>) :
              <CreateBatch />
            }

            {!filteredCardData.length && (
              <div className="h-100 w-100 my-5 py-5 d-flex align-items-center flex-column justify-content-center">
                <img
                  src={EmptyIcon}
                  className="img-fluid w-20"
                  alt="Empty Icon"
                />
                <p className="text-center">
                  No job card available at this stage
                </p>
              </div>
            )}
            {/* {jobCardData.length && login[0]?.FLD_Dept == 6 && (
              <div className="tray-shifting-floting-btn">
                <span
                  className="default-btn fs-5 w-100 d-block"
                  onClick={() => {
                    viewLot ? showDrawer() : createJobCardSlot();
                  }}>
                  {viewLot ? "View Card Lot" : "Create Job Card Lot"}
                </span>
              </div>
            )} */}
          </div>
        </div>
      </div>

      <Modal
        centered
        open={showAcceptConfirm}
        onCancel={onHideAcceptConfirm}
        onOk={UpdateJobCardStage}
        okText="Save"
        className="custom-alert-overlay logout-modal dispatch-save-jobcard-modal">
        <div className="login-box pb-0">
          <div className="section-title mb-0 mt-0 text-center">
            <div className="login-modal-icon fs-1">
              <img
                src={SaveIcon}
                className="img-fluid save-jobcard-img mb-0"
                alt="Save"
              />
            </div>
            <h4 className="text-center text-dark mb-0">Confirm To Save</h4>
            <p className="text-dark">
              Are you sure you want to save these details?
            </p>
            <div className=" d-flex gap-3 mb-3 justify-content-center w-auto job-card-save-prev">
              <p className="border px-2 py-1 border-theme mb-0">
                <span className="text-dark">Dead Stock :</span> {deadstock}
              </p>
              <p className="border px-2 py-1 border-theme mb-0">
                <span className="text-dark">Repairable :</span> {repairable}
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Offcanvas
        placement="end"
        size="sm"
        show={showFilters}
        onHide={handleCloseFilters}
        className="view-added-spares-modal dispatched-order-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter By</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-2">
            <div className="">
              <div className="d-flex gap-3 mb-3 list-all">
                <Select
                  value={selectedOption}
                  className="w-100"
                  // defaultValue={selectedOption}
                  // defaultValue={
                  //   login[0].FLD_User_Name != "Admin"
                  //     ? login[0].FLD_User_Name
                  //     : "List All"
                  // }
                  // style={{ width: 180 }}
                  onChange={handleChange}>
                  {deptDropdown
                    .sort((a, b) => a.Fld_Sequence - b.Fld_Sequence)
                    .map((dept) => (
                      <Select.Option
                        key={dept.FLD_Department_Id}
                        value={dept.FLD_Department_Name}>
                        {dept.FLD_Department_Name}
                      </Select.Option>
                    ))}
                </Select>
                <Button
                  onClick={() => {
                    setFromDate(null);
                    setToDate(null);
                    getJobCards();
                  }}
                  className="default-btn border-0 d-flex py-1 px-2 align-items-center">
                  <IoMdRefresh style={{ fontSize: "1.5rem" }} />
                </Button>
              </div>
              {login[0].FLD_User_Name != "Tray Shifting" &&
                <>
                  <div className="d-flex gap-3 mb-3">
                    <Select
                      value={selectedBrand}
                      placeholder="Choose Brand"
                      onChange={(value) => setSelectedBrand(value)}
                      className={"store-stock-select warehouse-select w-50"}>
                      <Select.Option key="all" value={null}>
                        All Brands
                      </Select.Option>
                      {brandList
                        .filter((brands) => brands.FLD_Enabled_Flag === "Active")
                        .map((brand) => (
                          <Select.Option
                            key={brand?.FLD_Brand_Id}
                            value={brand?.FLD_Brand_Name}>
                            {brand?.FLD_Brand_Name}
                          </Select.Option>
                        ))}
                    </Select>
                    <Select
                      value={selectedType}
                      placeholder="Choose Brand"
                      onChange={(value) => setSelectedType(value)}
                      className={"store-stock-select warehouse-select w-50"}>
                      <Select.Option key="all" value={null}>
                        All Types
                      </Select.Option>
                      {lighterTypes?.map((type) => (
                        <Select.Option
                          key={type?.FLD_Lighter_Type}
                          value={type?.FLD_Lighter_Type}>
                          {type?.FLD_Lighter_Type}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>


                  <div className="mb-2 d-flex flex-column gap-3">
                    <RangePicker
                      // allowClear={true}
                      value={
                        fromDate && toDate
                          ? [moment(fromDate), moment(toDate)]
                          : null
                      }
                      onChange={(date, dateString) => {
                        setFromDate(dateString[0]);
                        setToDate(dateString[1]);
                      }}
                    />
                    <div className="">
                      <Button
                        onClick={filterByDate}
                        className="default-btn border-0 py-1 d-flex align-items-center ms-auto">
                        Filter By Date
                      </Button>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* modal to show pdf data */}
      <Modal
        centered
        open={viewPDFModal}
        // onCancel={onHideAcceptConfirm}
        // onOk={UpdateJobCardStage}
        // okText="Save"
        footer={null}
        closable={false}
        width={1170}
        className="custom-alert-overlay logout-modal dispatch-save-jobcard-modal overflow-x-auto px-5 text-dark invisible table-lay-fix">
        <div
          id="downloaddata"
          className="warehouse-table download-pdf-modal job-card-list-table p-3 ms-4 ps-5 bg-white">
          <div className="download-pdf-modal-header border-bottom d-flex align-items-center justify-content-between w-100 bg-white pb-2 pb-lg-3 text-black font-weight-bold">
            <div></div>
            <div className="mb-0 ms-5 ps-5 text-center">
              <h4 className="mb-1">Shivaay International</h4>
              <p className="mb-0">
                Report For Job Card - {selectedJobCard[0]?.FLD_Job_Card_Number}
              </p>
            </div>

            <div className="date-time-badge">
              <p className="mb-0">
                <IoCalendar />
                {moment().format("DD/MM/YYYY")}
              </p>
              <span className="d-none d-lg-block">|</span>
              <p className="mb-0">
                <FaClock />
                {moment().format("h:mm a")}
              </p>
            </div>
          </div>
          <div>
            {/* <p className="mb-1 text-dark">
              <span>Job Card Number : </span>
              {selectedJobCard[0]?.FLD_Job_Card_Number}
            </p> */}
            <p className="mb-1 text-dark">
              <span>Total Quantity : </span>
              {selectedJobCard[0]?.FLD_Item_Qty}
            </p>
            <p className="mb-1 text-dark">
              <span>Current Quantity : </span>
              {selectedJobCard[0]?.FLD_Current_Quantity}
            </p>
            <p className="text-dark">
              <span>Stage : </span>
              {selectedJobCard[0]?.active_stage}
            </p>
          </div>
          <div className="d-flex gap-md-4 align-items-md-center text-dark flex-column flex-lg-row">
            <Table
              bordered
              pagination={false}
              className="operation-expense-table-size spare-request-table warehouse-request-table"
              columns={JobCardStageCols}
              dataSource={mappedJobCardData}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
