import { Select } from "antd";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { BsBox2Fill } from "react-icons/bs";
import { FaBoxes, FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import PostApiCall from "../../PostApi";

export default function SpareRequestList() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  // const location = useLocation();
  // const requestedSpare = location.state;
  const formstate = true;
  const [requestedSpare, setRequestedSpare] = useState([])
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [spareStatus, setSpareStatus] = useState(null)
  const [tempDispatchData, setTempDispatchData] = useState([]);
  const [reqId, setReqId] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [reqStore, setReqStore] = useState(null);

  useEffect(() => {
    Notiflix.Loading.dots("Please Wait...");
    var tempWhere =
      login[0].FLD_User_Type !== 4 && login[0].FLD_User_Type !== 3
        ? `where FLD_Created_By = ${login[0].FLD_User_Id} and FLD_Request_From = 'store' `
        : `where FLD_Request_From = 'store' `;

    PostApiCall.postRequest(
      {
        whereClause: tempWhere,
      },
      "GetSpareRequests"
    ).then((results) =>
      results.json().then((obj) => {
        try {
          if (results.status === 200 || results.status === 201) {

            setRequestedSpare(obj.data)
            Notiflix.Loading.remove();
          } else {
            Notiflix.Notify.failure(obj.data);
            Notiflix.Loading.remove();
          }
        } catch (error) {
          console.log(error);
          Notiflix.Loading.remove();
        }
      })
    );
    getStoreList();

  }, [])

  const getStoreList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetStoreList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setStoreList(obj.data.filter((store) => store.FLD_Enabled_Flag === "Active"));
          }
        })
    );
  };

  const onOpenDispatch = (data, requestId, status) => {
    console.log(JSON.parse(data?.item_details)?.items || [])
    const parsedItems = JSON.parse(data?.item_details)?.items || [];
    setReqId(requestId);
    setTempDispatchData(parsedItems);
    setShowDispatchModal(true);
    setSpareStatus(status)
  };
  const handlecloseDispatchMmodal = () => {
    setShowDispatchModal(false);
  };
  const handleDispatchItems = (id) => {
    if (reqStore && reqStore != null) {
      Notiflix.Loading.dots("Please Wait...");
      PostApiCall.postRequest(
        {
          reqid: id,
          status: "Dispatched",
          updatedby: login[0]?.FLD_User_Id,
          storeid: reqStore,
        },
        "DispatchItems"
      ).then((results) =>
        results.json().then((obj) => {
          try {
            if (results.status === 200 || results.status === 201) {
              Notiflix.Loading.remove();
              Notiflix.Notify.success("Items Dispatched Successfully!");
              // clear the states
              setTempDispatchData([]);
              // getSpareRequests();
              handlecloseDispatchMmodal();
            } else {
              Notiflix.Notify.failure(obj.data);
              Notiflix.Loading.remove();
            }
          } catch (error) {
            console.log(error);
            Notiflix.Loading.remove();
          }
        })
      );
    } else {
      Notiflix.Notify.failure("Please select a store to dispatch items from");
    }
  };
  return (
    <>
      <div className="margin-left blank-section job-card-page supervisor-dashboard spare-card-list-page">
        <div className="container-fluid pt-4 pb-2">
          <div className="row pt-4 mt-4 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card default-card-border">
                <div className="card-heading d-flex justify-content-between align-items-lg-center gap-3 flex-column flex-lg-row">
                  <h4 className="mb-0">Spare Request List</h4>
                  <div className="mb-2 mb-lg-0">
                    <Link
                      to="/spares-request-form"
                      state={formstate}
                      className="default-btn"
                    >
                      Request a Spare
                    </Link>
                  </div>
                </div>
                <div className="job-card-main-box spare-card-list">
                  {requestedSpare?.map((spares, index) => {
                    return (
                      <div key={index} className="job-card-box">
                        <div className="box">
                          <BsBox2Fill />
                          <p>{spares.items_name}</p>
                        </div>
                        <div className="box">
                          <FaBoxes />
                          <p>{spares.items_total_count}</p>
                        </div>
                        <div className="box">
                          <FaCalendarAlt />
                          <p>{spares.FLD_Spares_Stock_Request_Date}</p>
                        </div>
                        <div className="box justify-content-end border-0">
                          <button
                            type="button"
                            className="default-btn mt-2 border-0"
                            onClick={() => {
                              onOpenDispatch(
                                spares,
                                spares.FLD_Spares_Request_Id,
                                spares.FLD_Enabled_Flag
                              );
                            }}
                          >
                            View
                          </button>
                        </div>
                        <div className={spares.FLD_Enabled_Flag == "Dispatched" ? "status-box-dispatched" : "status-box"}>
                          <p>{spares.FLD_Enabled_Flag}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        placement="end"
        show={showDispatchModal}
        onHide={handlecloseDispatchMmodal}
        className="view-added-spares-modal"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Dispatch Items</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* {console.log(tempDispatchData)} */}
          {tempDispatchData?.length === 0 ? (
            <p>No items added yet!</p>
          ) : (
            <ul>
              {tempDispatchData &&
                tempDispatchData?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="d-flex justify-content-between align-items-center mb-3"
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            item.FLD_Item_Spare_Image ||
                            "path/to/default-image.jpg"
                          }
                          alt={item?.Name}
                          className="img-fluid"
                          style={{
                            width: "50px",
                            height: "50px",
                            marginRight: "10px",
                          }}
                        />
                        <div>
                          <strong>{item?.FLD_Item_Spare_Name}</strong>
                          <br />
                          <span>Quantity: {item?.FLD_Spares_Quantity}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          )}
          {spareStatus === "Dispatched" ?
            <div className="mt-4 d-grid sticky-bottom">
              <Button
                size="lg"
                className="border-0 default-btn"
              >
                Already Disptached
              </Button>
            </div>
            :
            <Select
              size="large"
              className="input-box"
              placeholder="Choose Store"
              style={{
                width: "100%",
              }}
              onChange={(value) => setReqStore(value)}
            >
              {storeList.map((store) => (
                <Select.Option
                  key={store.FLD_Store_Id}
                  value={store.FLD_Store_Id}
                >
                  {store.FLD_Store_Name}
                </Select.Option>
              ))}
            </Select>}
          {spareStatus !== "Dispatched" && <div className="mt-4 d-grid sticky-bottom">
            <Button
              size="lg"
              className="border-0 default-btn"
              onClick={() => handleDispatchItems(reqId)}
            >
              Dispatch Items
            </Button>
          </div>}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
