import { Input } from "antd";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { LuLogOut } from "react-icons/lu";
import { MdLockReset } from "react-icons/md";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import moment from "moment";
export default function ChangePasswordModal({
  showChangePassword,
  onHideChangePassword,
}) {

  const [password, setPassword] = useState("")
  const [confirmPass, setConfirmPass] = useState("")

  const changePassword = () => {
    const login = JSON.parse(localStorage.getItem("LoginDetail"));
    if (password === "") {
      return Notiflix.Notify.failure("Please enter password");
    }
    if (confirmPass === "") {
      return Notiflix.Notify.failure("Please enter confirm password");
    }
    if (password !== confirmPass) {
      return Notiflix.Notify.failure("Password does not match")
    }
    Notiflix.Loading.dots()
    PostApiCall.postRequest(
      {
        userid: login[0]?.FLD_User_Id,
        password: password,
        updatedon: moment().format("lll"),
        updatedby: login[0]?.FLD_User_Id,
        salt: login[0]?.FLD_Salt
      },
      "ChangePassword"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setPassword("")
          setConfirmPass("")
          Notiflix.Loading.remove()
          Notiflix.Notify.success("Password changed successfully")
          onHideChangePassword()
        }
      })
    );
  }
  return (
    <>
      <Modal
        centered
        show={showChangePassword}
        onHide={onHideChangePassword}
        className="logout-modal">
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="login-box pb-0">
            <div className="section-title mb-0 mt-0 text-center">
              <div className="login-modal-icon fs-1">
                <MdLockReset />
              </div>
              <h4 className="text-center text-dark mb-3">Change Password</h4>
              {/* <p className="text-dark">???</p> */}
            </div>
            <div>
              <label className="mb-1">New Password</label>
              <Input.Password size="large" className="mb-3" onChange={(e) => setPassword(e.target.value)} />
              <label className="mb-1">Confirm Password</label>
              <Input.Password
                onChange={(e) => setConfirmPass(e.target.value)}
                size="large"
                className="mb-3"
              />
            </div>
            <div className="text-end">
              <button className="default-btn border-0" onClick={changePassword}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
