import { Modal, Select, Table } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { FaBox, FaBoxes, FaClock, FaList, FaWarehouse } from "react-icons/fa";
import { GiWeight } from "react-icons/gi";
import { IoIosColorPalette } from "react-icons/io";
import { IoCalendar } from "react-icons/io5";
import { MdAddToPhotos } from "react-icons/md";
import { TbLighter } from "react-icons/tb";
import { store } from "../../context/Store";
import PostApiCall from "../../PostApi";

export default function WarehouseItemStock() {
  const [activeTab, setActiveTab] = useState("store");
  const [warehouseStock, setWrehouseStock] = useState([]);
  const [filterDropdown, setFilterDropdown] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [filteredId, setFilteredId] = useState(null);
  const [lighterType, setLighterType] = useState("");

  // const [wareHouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [warehouseId, setWarehouseId] = useState(null);
  const [storeStock, setStoreStock] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [searchedBrand, setSearchedBrand] = useState(null);

  const { brandList } = useContext(store)

  const handleChangeWareHouse = (value) => {
    setWarehouseId(value);
    const warehouseName = warehouseList.filter(
      (warehouse) => warehouse.FLD_Warehouse_Id === value
    );
    setSelectedWarehouse(warehouseName[0].FLD_Warehouse_Name);
  };
  const handleChange = (value) => {
    // const storeName = storeList.filter((item) => item.FLD_Store_Id === value)
    const type =
      value === 27 ? "Electric" : value === 28 ? "Windproof" : "Black Edition";
    setLighterType(type);
    let csvRows;
    let csvCols;

    setFilteredId(value);
    //  dynamic csv data
    // eslint-disable-next-line
    if (activeTab == "store") {
      csvCols = ["S No.", "Item Name", "Item Color", "Total Stock"];
      csvRows = storeStock
        // eslint-disable-next-line
        .filter((stock) => stock.FLD_Store_Id == value)
        .map((item, index) => [
          index + 1,
          item.spare_name,
          item.spare_color,
          item.quantity,
        ]);
      // eslint-disable-next-line
    } else if (activeTab == "warehouse") {
      csvCols = [
        "S No.",
        "Brand",
        "Item Name",
        "Item Color",
        "No. of Pieces",
        "Apx. Box Weight (in Kgs)",
        "Available Boxes",
      ];
      csvRows = warehouseStock
        // eslint-disable-next-line
        .filter((warehouse) => warehouse.FLD_Warehouse_Id == value)
        .map((item, index) => [
          index + 1,
          item.BrandName ? item.BrandName : "N/A",
          item.item_name,
          item.item_color,
          item.FLD_Warehouse_Item_Quantity,
          (item?.item_weight * item?.FLD_Warehouse_Item_Quantity) / 100
            ? (item?.item_weight * item?.FLD_Warehouse_Item_Quantity) / 100
            : "N/A",
          item?.FLD_Bags_Cartons,
        ]);
    } else if (activeTab == "readygoods") {
      csvCols = ["S No.", "Stock Type", "Brand", "Item Name", "No. of Pieces"];
      csvRows = warehouseStock
        .filter((warehouse) => warehouse.FLD_Warehouse_Id == value)
        .map((item, index) => [
          index + 1,
          item.FLD_Stock_Type,
          item.BrandName,
          item.item_name,
          item.FLD_Warehouse_Item_Quantity,
        ]);

    }
  };
  useEffect(() => {
    const date = new Date();
    const getStoreList = () => {
      PostApiCall.postRequest({ whereClause: "" }, "GetStoreList").then(
        (results) =>
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              setFilterDropdown(
                obj.data.filter(
                  (storedata) => storedata.FLD_Enabled_Flag == "Active"
                )
              );
            }
          })
      );
    };
    const getWarehouses = () => {
      PostApiCall.postRequest(
        { whereClause: "" },
        "GetWarehouseMasterList"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setWarehouseList(obj.data);
          }
        })
      );
    };
    PostApiCall.postRequest({}, "GetWarehouseItemStockList").then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setWrehouseStock(obj.data);
        }
      })
    );
    PostApiCall.postRequest({}, "GetItemSpareStockList").then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setStoreStock(obj.data);
        }
      })
    );
    getStoreList();
    getWarehouses();
  }, []);
  dayjs.extend(customParseFormat);

  // warehouse cols
  const warehouseStockCols = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Brand",
      dataIndex: "brandname",
      key: "brandname",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Item Color",
      dataIndex: "itemcolor",
      key: "itemcolor",
    },
    {
      title: "No. of Pieces",
      dataIndex: "totalStock",
      key: "totalStock",
    },
    {
      title: "No. of Boxes/Cartons",
      dataIndex: "boxCount",
      key: "boxCount",
    },
    {
      title: "Apx. Box Weight (in kgs)",
      dataIndex: "weight",
      key: "weight",
    },
  ];

  // Ready goods cols
  const readyGoodsCols = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Stock Type",
      dataIndex: "stocktype",
      key: "stocktype",
    },
    {
      title: "Brand",
      dataIndex: "brandname",
      key: "brandname",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "No. of Cartons/Bags",
      dataIndex: "bagcount",
      key: "bagcount",
    },
    {
      title: "No. of Pieces",
      dataIndex: "totalStock",
      key: "totalStock",
    },
    {
      title: "Extra Items",
      dataIndex: "extraitems",
      key: "extraitems",
    },
  ];

  // store cols
  const columns = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Item Color",
      dataIndex: "itemcolor",
      key: "itemcolor",
    },
    {
      title: "No. of Pieces",
      dataIndex: "totalStock",
      key: "totalStock",
    },
    {
      title: "No. Cartons / Bags",
      dataIndex: "cartonsbags",
      key: "cartonsbags",
    },
  ];

  // warehouse stock row
  var mappedData = warehouseStock
    .filter((warehouse) =>
      filteredId !== null
        ? warehouse.FLD_Lighter_Type == lighterType &&
        warehouse.FLD_Warehouse_Id == warehouseId &&
        warehouse.FLD_Stock_Type === "Spare"
        : warehouse.FLD_Warehouse_Id == warehouseId &&
        warehouse.FLD_Stock_Type === "Spare"
    )
    .filter(
      (item) =>
        searchedBrand === null ||
        item?.BrandName?.toLowerCase().includes(searchedBrand?.toLowerCase())
    )
    .map((warehouseData, index) => {
      return {
        key: index + 1,
        serial: index + 1,
        itemName: warehouseData?.item_name,
        brandname: warehouseData?.BrandName,
        itemcolor: warehouseData?.item_color,
        totalStock: warehouseData?.FLD_Warehouse_Item_Quantity,
        weight:
          (warehouseData?.item_weight *
            warehouseData?.FLD_Warehouse_Item_Quantity) /
          100,
        boxCount: warehouseData?.FLD_Bags_Cartons,
      };
    });

  // store stock row
  const mappedStoreStock = storeStock
    .filter((stock) => filteredId === null || stock.FLD_Store_Id == filteredId)
    .map((stock, index) => {
      return {
        key: index + 1,
        serial: index + 1,
        itemName: `${stock?.spare_name} - ${stock?.brand_name}`,
        itemcolor: stock?.spare_color,
        totalStock: stock?.quantity,
        cartonsbags: stock?.FLD_Bags_Cartons
      };
    });

  // ready goods row
  const readyGoodsRows = warehouseStock
    .filter((warehouse) =>
      filteredId !== null
        ? warehouse.FLD_Lighter_Type == lighterType &&
        warehouse.FLD_Warehouse_Id == warehouseId &&
        warehouse.FLD_Stock_Type !== "Spare"
        : warehouse.FLD_Warehouse_Id == warehouseId &&
        warehouse.FLD_Stock_Type !== "Spare"
    )
    .map((stock, index) => {
      return {
        key: index + 1,
        serial: index + 1,
        stocktype: stock?.FLD_Stock_Type,
        brandname: stock?.BrandName,
        itemName: stock?.item_name,
        bagcount: stock?.Fld_Cartons,
        totalStock: stock?.FLD_Warehouse_Item_Quantity,
        extraitems: stock?.FLD_Extra_Items
      };
    });

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    setFilteredId(null);
    setWarehouseId(null);
    // if (tab == "warehouse" || tab == "readygoods") {
    //   setFilterDropdown(warehouseList);
    //   setFilteredId(null);
    // } else {
    //   setFilterDropdown(storeList);
    //   setFilteredId(null);
    // }
  };

  const downloadPdf = () => {
    setTimeout(() => {
      downloadPdfI()
    }, 100);
  };
  const downloadPdfI = async () => {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("downloaddata");
    var opt = {
      margin: 1,
      filename: activeTab == "store" ? "Store Stock Report" : (activeTab == "warehouse" ? "Warehouse Stock Report" : "Ready Goods Report"),
      image: { type: "png", quality: 1.9 },
      html2canvas: {
        scale: 1.1,
        useCORS: true,
        allowTaint: true,
        proxy: null,
        dpi: 192,
      },
      pagebreak: { mode: 'css' },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        compress: true,
        auto: true,
      },
    };
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        setViewModal(false);
        Notiflix.Loading.remove()
      });
  };

  const screenSize = window.innerWidth;

  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-0 h-100">
        <div className="row pt-4 mt-4 mb-0 px-0 h-100">
          <div className="col-12 px-0 h-100">
            <div className="default-card default-card-border job-card-list-table">
              <div className="card-heading">
                <h4>
                  {" "}
                  {activeTab == "store"
                    ? "Store Stock Statement"
                    : "Warehouse Stock Statement"}
                </h4>
              </div>
              <div className="tab-filter">
                <Tab.Container
                  defaultActiveKey="store"
                  onSelect={handleTabSelect}>
                  <div className=" mb-4 w-100 flex-wrap gap-4 d-flex justify-content-between align-items-end">
                    <div className="overflow-x-auto store-warehouse-readygoods">
                      <Nav
                        variant="pills"
                        className="flex-row m-0 w-auto ml-auto">
                        <Nav.Item>
                          <Nav.Link eventKey="store">Store</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="warehouse">Warehouse</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="readygoods">Ready Goods</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="text-dark d-flex flex-wrap gap-3 choose-warehouse">
                      {activeTab !== "store" && (
                        <Select
                          value={warehouseId}
                          placeholder="Choose Warehouse"
                          onChange={handleChangeWareHouse}
                          className={
                            activeTab === "warehouse"
                              ? "store-stock-select warehouse-select"
                              : "store-stock-select warehouse-select"
                          }>
                          {warehouseList.map((warehouse) => (
                            <Select.Option
                              key={
                                warehouse.FLD_Store_Id
                                  ? warehouse.FLD_Store_Id
                                  : warehouse.FLD_Warehouse_Id
                              }
                              value={
                                warehouse.FLD_Store_Id
                                  ? warehouse.FLD_Store_Id
                                  : warehouse.FLD_Warehouse_Id
                              }>
                              {warehouse?.FLD_Store_Name
                                ? warehouse?.FLD_Store_Name
                                : warehouse?.FLD_Warehouse_Name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                      {activeTab === "warehouse" && (
                        <div>
                          <Select
                            value={searchedBrand}
                            placeholder="Choose Brand"
                            onChange={(value) => setSearchedBrand(value)}
                            className={
                              "store-stock-select warehouse-select"
                            }>
                            <Select.Option key="all" value={null}>
                              All Brands
                            </Select.Option>
                            {brandList.filter((brands) => brands.FLD_Enabled_Flag === "Active").map((brand) => (
                              <Select.Option
                                key={brand?.FLD_Brand_Id
                                }
                                value={brand?.FLD_Brand_Name
                                }>
                                {brand?.FLD_Brand_Name}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      {activeTab !== "readygoods" && (
                        <Select
                          value={filteredId}
                          placeholder={"Choose Store"}
                          className="store-stock-select"
                          onChange={handleChange}>
                          <Select.Option key="all" value={null}>
                            All
                          </Select.Option>
                          {filterDropdown.map((type) => (
                            <Select.Option
                              key={type.FLD_Store_Id}
                              value={type.FLD_Store_Id}>
                              {type?.FLD_Store_Name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                      <div className="stock-down-btn">
                        {/* <CSVLink
                          data={csvData ? csvData : ""}
                          filename={csvFileName}
                          className="d-inline"
                        >
                          <button type="button" className="default-btn border-0">
                            Download CSV
                          </button>
                        </CSVLink> */}
                        <button
                          onClick={() => {
                            Notiflix.Loading.dots()
                            setViewModal(true);
                            downloadPdf();
                          }}
                          type="button"
                          className="default-btn border-0">
                          Download Pdf
                        </button>
                      </div>
                    </div>

                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="store">
                      {screenSize > 568 ? (
                        <Table
                          bordered
                          className="operation-expense-table-size "
                          columns={columns}
                          dataSource={mappedStoreStock}
                        />
                      ) : (
                        <div className="supervisor-dashboard spare-card-list-page job-card-page">
                          <div className="job-card-main-box spare-card-list mb-2 mobile-stock-card-box">
                            {mappedStoreStock?.map((spares, index) => {
                              return (
                                <div
                                  key={index}
                                  className="job-card-box store-stcok-mcard d-flex flex-wrap">
                                  <div className="box w-100 pb-3 mb-2">
                                    <TbLighter />
                                    <h6 className="mb-0">{spares?.itemName}</h6>
                                  </div>
                                  <div className="box w-100 justify-content-between pb-0">
                                    <p className="w-50 mb-0 fs-6 pt-0">
                                      <IoIosColorPalette /> {spares?.itemcolor}
                                    </p>

                                    <p className="w-50 mb-0 justify-content-end fs-6 pt-0 border-start">
                                      <FaBoxes /> {spares.totalStock}
                                    </p>
                                    <p className="w-50 mb-0 justify-content-end fs-6 pt-0 border-start">
                                      <FaBox /> {spares.FLD_Bags_Cartons}
                                    </p>
                                  </div>
                                  {/* <div className="box">
                                    <p>No. of Boxes/Cartons -</p><p>{spares?.boxCount}</p>
                                  </div> */}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="warehouse">
                      {screenSize > 568 ? (
                        <Table
                          bordered
                          className="operation-expense-table-size spare-request-table warehouse-request-table"
                          columns={warehouseStockCols}
                          dataSource={mappedData}
                        />
                      ) : (
                        <div className="supervisor-dashboard spare-card-list-page job-card-page">
                          <div className="job-card-main-box spare-card-list mb-2 mobile-stock-card-box">
                            {mappedData.length > 0 ? mappedData?.map((spares, index) => {
                              return (
                                <div
                                  key={index}
                                  className="job-card-box d-flex flex-wrap">
                                  <div className="box w-100">
                                    <h6 className="d-flex">
                                      <TbLighter />
                                      {spares?.itemName} - {spares.brandname}
                                    </h6>
                                  </div>
                                  <div className="box w-50">
                                    <p className="fs-6">
                                      <IoIosColorPalette />
                                      {spares?.itemcolor}
                                    </p>
                                  </div>
                                  <div className="box w-50">
                                    <p className="fs-6">
                                      <FaBoxes />
                                      {spares.totalStock}
                                    </p>
                                  </div>
                                  <div className="box w-50">
                                    <p className="mb-0 fs-6">
                                      <FaBox />
                                      {spares.boxCount}
                                    </p>
                                  </div>
                                  <div className="box w-50">
                                    <p className="mb-0 fs-6">
                                      <GiWeight /> {spares.weight} Kgs
                                    </p>
                                  </div>
                                </div>
                              );
                            }) : <div className="my-5 py-5 text-center d-flex flex-column w-100 align-items-center">
                              <FaWarehouse className="fs-1 mb-2" />
                              Choose a Warehouse</div>}
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="readygoods">
                      {screenSize > 568 ? (
                        <Table
                          bordered
                          className="operation-expense-table-size"
                          columns={readyGoodsCols}
                          dataSource={readyGoodsRows}
                        />
                      ) : (
                        <div className="supervisor-dashboard spare-card-list-page job-card-page">
                          <div className="job-card-main-box spare-card-list mb-2 mobile-stock-card-box">
                            {readyGoodsRows.length > 0 ? readyGoodsRows?.map((spares, index) => {
                              return (
                                <div
                                  key={index}
                                  className="job-card-box d-flex flex-wrap">
                                  <div className="box w-100 align-items-center pb-3">
                                    <TbLighter />
                                    <h6 className="mb-0">
                                      {spares.brandname} - {spares?.itemName}
                                    </h6>
                                  </div>

                                  <div className="box w-50">
                                    <p className=" fs-6">
                                      <FaList />
                                      {spares?.stocktype}
                                    </p>
                                  </div>
                                  <div className="box w-50">
                                    <p className=" fs-6">
                                      <FaBoxes />
                                      {spares.totalStock}
                                    </p>
                                  </div>
                                  <div className="box w-50">
                                    <p className="mb-0 fs-6">
                                      <FaBox />
                                      {spares.bagcount}
                                    </p>
                                  </div>
                                  <div className="box w-50">
                                    <p className="mb-0 fs-6">
                                      <MdAddToPhotos />
                                      {spares.extraitems}
                                    </p>
                                  </div>
                                </div>
                              );
                            }) : <div className="my-5 py-5 text-center d-flex flex-column w-100 align-items-center">
                              <FaWarehouse className="fs-1 mb-2" />
                              Choose a Warehouse</div>}
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>



      <Modal
        centered
        open={viewModal}
        // onCancel={onHideAcceptConfirm}
        // onOk={UpdateJobCardStage}
        // okText="Save"
        footer={null}
        closable={false}
        width={1170}
        className="custom-alert-overlay logout-modal dispatch-save-jobcard-modal overflow-x-auto px-5 text-dark invisible table-lay-fix">
        <div
          id="downloaddata"
          className="warehouse-table download-pdf-modal job-card-list-table p-3 ms-4 ps-5 bg-white">
          <div className="download-pdf-modal-header border-bottom d-flex align-items-center justify-content-between w-100 bg-white pb-2 pb-lg-3 text-black font-weight-bold">
            <div></div>
            <div className="mb-0 ms-5 ps-5 text-center">
              <h4 className="mb-1">Shivaay International</h4>
              <p className="mb-0">{activeTab == "store" ? "Store Stock Report" : (activeTab == "warehouse" ? "Warehouse Stock Report" : "Ready Goods Report")}</p>
            </div>

            <div className="date-time-badge">
              <p className="mb-0">
                <IoCalendar />
                {moment().format("DD/MM/YYYY")}
              </p>
              <span className="d-none d-lg-block">|</span>
              <p className="mb-0">
                <FaClock />
                {moment().format("h:mm a")}
              </p>
            </div>
          </div>
          <div className="mb-2">

            {activeTab !== "store" && (<p className="mb-1 text-dark">
              <span>Warehouse : </span>
              {selectedWarehouse}
            </p>)}
            <p className="mb-1 text-dark">
              <span>Brand : </span>
              {searchedBrand !== null ? searchedBrand : "All"}
            </p>

            <p className="mb-1 text-dark">
              <span>Lighter Type : </span>
              {lighterType !== "" ? lighterType : "All"}
            </p>
          </div>
          <div className="d-flex gap-md-4 align-items-md-center text-dark flex-column flex-lg-row">
            <Table
              bordered
              pagination={false}
              className="operation-expense-table-size spare-request-table warehouse-request-table"
              columns={
                activeTab === "store"
                  ? columns
                  : activeTab == "warehouse"
                    ? warehouseStockCols
                    : readyGoodsCols
              }
              dataSource={
                activeTab === "store"
                  ? mappedStoreStock
                  : activeTab == "warehouse"
                    ? mappedData
                    : readyGoodsRows
              }
            />
          </div>

        </div>
      </Modal>
      {/* <Modal show={viewModal} size="xl" centered>
        <Modal.Body className="rounded p-0 overflow-hidden download-pdf-modal px-5 bg-white">
          <div
            id="downloaddata"
            className="warehouse-table job-card-list-table p-3 ps-5 ms-4 bg-white">
            <div className="download-pdf-modal-header border-bottom d-flex align-items-center justify-content-between w-100 bg-white pb-2 pb-lg-3 text-black font-weight-bold">
              <div></div>
              <h4 className="mb-0 ms-5 ps-5">Shivaay International</h4>

              <div className="date-time-badge">
                <p className="mb-0">
                  <IoCalendar />
                  {moment().format("DD/MM/YYYY")}
                </p>
                <span className="d-none d-lg-block">|</span>
                <p className="mb-0">
                  <FaClock />
                  {moment().format("h:mm a")}
                </p>
              </div>
            </div>
            <div className="d-flex gap-md-4 align-items-md-center text-dark flex-column flex-lg-row">
              {activeTab !== "store" && (
                <h6 className={activeTab !== "store" ? "my-2 my-lg-3" : ""}>
                  {selectedWarehouse}
                </h6>
              )}
              {searchedBrand !== null && (
                <h6 className="mb-3 mt-lg-3">{searchedBrand}</h6>
              )}
              {lighterType !== "" && activeTab !== "store" && (
                <span className="d-none d-lg-block">|</span>
              )}
              {lighterType !== "" && (
                <h6
                  className={
                    lighterType !== "" && activeTab !== "store"
                      ? "mb-3 mt-lg-3"
                      : "my-3"
                  }>
                  {lighterType}
                </h6>
              )}
            </div>
            <Table
              bordered
              pagination={false}
              className="operation-expense-table-size spare-request-table warehouse-request-table"
              columns={
                activeTab === "store"
                  ? columns
                  : activeTab == "warehouse"
                    ? warehouseStockCols
                    : readyGoodsCols
              }
              dataSource={
                activeTab === "store"
                  ? mappedStoreStock
                  : activeTab == "warehouse"
                    ? mappedData
                    : readyGoodsRows
              }
            />
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
