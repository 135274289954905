import { DatePicker, Input, Select } from "antd";
import moment from "moment";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useForm } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import NoImg from "../../assets/img/no-img.jpg";
import { store } from "../../context/Store";
import PostApiCall from "../../PostApi";

export default function OrderMaster() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const [reqWarehouse, setReqWarehouse] = useState(null);
  const [warehouseList, setWarehouseList] = useState([]);
  const [itemsname, setItemsName] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerType, setCustomerType] = useState("Old");
  const { brandUniqId } = useContext(store);
  const [customerId, setCustomerId] = useState(null);
  const [localItems, setLocalItems] = useState([]);
  const [dispatchDate, setDispatchDate] = useState(null);
  const [show, setShow] = useState(false); // get the added item drawer

  const {
    setValue,
    clearErrors,
    formState: { errors },
    handleSubmit,
    reset,
    register,
  } = useForm();
  useEffect(() => {
    getWarehouses();
    getitemList();
    getCustomerList();
  }, []);

  const handleRemoveItem = (itemid) => {
    const updatedLocalStorage = localItems.filter(
      (diaptchItem) => diaptchItem.Item !== itemid
    );
    localStorage.setItem(
      "itemstoDispatch",
      JSON.stringify(updatedLocalStorage)
    );

    setLocalItems(updatedLocalStorage);
  };

  const handleItemToLocal = (itemid, spareimg, sparename, brandname) => {
    // Quantity input value
    const inputElement = document.getElementById(`quantity-${itemid}`);
    const quantity = inputElement ? parseInt(inputElement.value, 10) : 0;
    if (quantity <= 0 || isNaN(quantity)) {
      Notiflix.Notify.failure("Invalid quantity");
      return;
    }

    // existing local data
    const existingItems =
      JSON.parse(localStorage.getItem("itemstoDispatch")) || [];

    // check for spare item in existing data
    const itemIndex = existingItems.findIndex((spare) => spare.Item === itemid);

    if (itemIndex >= 0) {
      // update the count if it exists in localstorage
      existingItems[itemIndex].Quantity += quantity;
    } else {
      // new item to localstorage
      existingItems.push({
        Item: itemid,
        Quantity: quantity,
        Image: spareimg,
        ItemName: `${sparename} - ${brandname}`,
      });
    }

    // update the localstorage
    localStorage.setItem("itemstoDispatch", JSON.stringify(existingItems));
    // inputElement.value = "";

    Notiflix.Notify.success("Item added successfully!");
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  const handleShow = () => {
    const existingItems =
      JSON.parse(localStorage.getItem("itemstoDispatch")) || [];
    setLocalItems(existingItems); // Update state with items
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const getWarehouses = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetWarehouseMasterList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setWarehouseList(obj.data);
          }
        })
    );
  };
  const getitemList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetitemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setItemsName(
              // eslint-disable-next-line
              obj.data.filter((item) => item.FLD_Enabled_Flag == "Active")
            );
          }
        })
    );
  };
  const getCustomerList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetCustomerList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setCustomerList(obj.data);
          }
        })
    );
  };

  const onSaveCustomer = (formData) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        customerid: null,
        customername: formData.customername,
        customeraddress: formData.customeraddress,
        customermobile: formData.customermobile,
        status: "Active",
      },
      "AddCustomer"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          reset({
            customername: "",
            customermobile: "",
            customeraddress: "",
          });
          setCustomerType("Old");
          getCustomerList();
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };

  const dispatchItemsToCustomer = (items) => {
    if (customerId === null) {
      return Notiflix.Notify.failure("Please select customer");
    }
    if (reqWarehouse === null) {
      return Notiflix.Notify.failure("Please select warehouse");
    }
    if (dispatchDate === null || dispatchDate === "") {
      return Notiflix.Notify.failure("Please select dispatch date");
    }
    const jsonData = Object.values(items);
    const itemLocal = JSON.stringify(jsonData);
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        userid: login[0]?.FLD_User_Type,
        itemdetails: itemLocal,
        customerid: customerId,
        warehouseid: reqWarehouse,
        dispatchDate: dispatchDate
      },
      "DispatchItemsToCustomer"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          reset({
            customername: "",
            customermobile: "",
            customeraddress: "",
          });
          setCustomerType("Old");
          getCustomerList();
          setCustomerId(null);
          setReqWarehouse(null);
          localStorage.removeItem("itemstoDispatch");
          handleClose();
          Notiflix.Notify.success(obj.data[0]?.result)
        } else {
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  // get the brand name
  const getItemAdditionalInfo = (id) => {
    const brand = brandUniqId.find((entry) => entry?.FLD_Brand_Id === id);
    return brand?.FLD_Brand_Name;
  };
  return (
    <div className="margin-left">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Create and Dispatch Order</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 pb-3">
                  {customerType === "New" ? (
                    <>
                      <label>
                        Customer Name<span className="text-danger">*</span>
                      </label>
                      <Input
                        size="large"
                        placeholder="Enter Customer Name"
                        allowClear
                        className="input-box"
                        id="customername"
                        {...register("customername", {
                          required: "Customer name is required",
                        })}
                        onChange={handleChange("customername")}
                      />
                      {errors.customername && (
                        <p className="text-danger">
                          {errors.customername.message}
                        </p>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {// eslint-disable-next-line
                    customerType == "Old" && (
                      <>
                        <label className="mb-2">
                          Customer Name / Mobile <span className="text-danger">*</span>
                        </label>
                        <Select
                          size="large"
                          className="input-box"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.children ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          id="customernamemobile"
                          {...register("customernamemobile", {
                            required: "Customer is required",
                          })}
                          value={customerId}
                          placeholder="Search and Choose a Customer"
                          onChange={(value) => {
                            // eslint-disable-next-line
                            if (value == "New") {
                              setCustomerType(value); // toggle list and input
                            } else {
                              setCustomerId(value);
                              setValue("customerid", value);
                            }
                            clearErrors("customerid");
                          }}
                        >
                          {customerList.map((customer) => (
                            <Select.Option
                              key={customer.FLD_Customer_Id}
                              value={customer.FLD_Customer_Id}
                            >
                              {customer.searchfield}
                            </Select.Option>
                          ))}
                          <Select.Option value="New">Add New</Select.Option>
                        </Select>
                      </>
                    )}
                </div>
                {customerType === "New" ? (
                  <>
                    <div className="col-lg-4 pb-3">
                      <label>
                        Customer's Mobile<span className="text-danger">*</span>
                      </label>
                      <Input
                        size="large"
                        placeholder="Customer's Mobile"
                        allowClear
                        className="input-box"
                        // value={watch("customermobile") || ""}
                        id="customermobile"
                        {...register("customermobile", {
                          required: "Mobile number is required",
                          pattern: {
                            value: /^\d{10}$/,
                            message: "customermobile must be 10 digits",
                          },
                        })}
                        onChange={handleChange("customermobile")}
                      />
                      {errors.customermobile && (
                        <p className="text-danger">
                          {errors.customermobile.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-4 pb-3">
                      <label>
                        Customer's Address / Location<span className="text-danger">*</span>
                      </label>
                      <Input
                        size="large"
                        placeholder="Enter Address / Location"
                        allowClear
                        className="input-box"
                        // value={watch("customeraddress") || ""}
                        id="customeraddress"
                        {...register("customeraddress", {
                          required: "Customer address is required",
                        })}
                        onChange={handleChange("customeraddress")}
                      />
                      {errors.customeraddress && (
                        <p className="text-danger">
                          {errors.customeraddress.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-4 pb-3">
                      <Button
                        className="default-btn border-0"
                        onClick={handleSubmit(onSaveCustomer)}
                      >
                        Save
                      </Button>
                      <Button
                        className="default-btn border-0 ms-2"
                        onClick={() => setCustomerType("Old")}
                      >
                        Go Back
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="row">
                  <label className="mb-2">
                    Choose Items and Quantities to Dispatch<span className="text-danger">*</span>
                  </label>
                  {itemsname
                    // eslint-disable-next-line
                    ?.filter((item) => item.FLD_Enabled_Flag == "Active")
                    ?.map((item) => {
                      return (
                        <div
                          key={item.FLD_Item_Id}
                          className="col-6 col-lg-2 mb-3"
                        >
                          <div className="border spare-request-card">
                            <img
                              src={item?.FLD_Item_Image || NoImg}
                              className="img-fluid w-100 mb-3"
                              alt=""
                              loading="lazy"
                            />
                            <h6 className="mb-2">
                              {item.FLD_Item_Name} -{" "}
                              {getItemAdditionalInfo(item?.FLD_Brand_Id)}
                            </h6>
                            <p className="text-theme">Available Stock - {item?.available_stock}</p>
                            <label
                              htmlFor={`quantity-${item.FLD_Item_Spares_Id}`}
                              className="mb-1"
                            >
                              Number of Cartons
                            </label>
                            <Input
                              id={`quantity-${item.FLD_Item_Id}`}
                              size="small"
                              allowClear
                              className="input-box p-1"
                            />
                            <button
                              className="default-btn fs-6 border-0 mt-3 mt-lg-2"
                              onClick={() => {
                                handleItemToLocal(
                                  item.FLD_Item_Id,
                                  item.FLD_Item_Image,
                                  item.FLD_Item_Name,
                                  getItemAdditionalInfo(item?.FLD_Brand_Id),
                                );
                              }}
                            >
                              Add Item(s)
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="col-lg-4 pb-3">
                  <label className="mb-2">
                    Choose a warehouse you want to dispatch from<span className="text-danger">*</span>
                  </label>
                  <Select
                    size="middle"
                    className="input-box"
                    placeholder="Choose a Warehouse"
                    value={reqWarehouse}
                    onChange={(value) => setReqWarehouse(value)}
                  >
                    {warehouseList.map((warehouse) => (
                      <Select.Option
                        key={warehouse.FLD_Warehouse_Id}
                        value={warehouse.FLD_Warehouse_Id}
                      >
                        {warehouse.FLD_Warehouse_Name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="col-lg-4 pb-3">
                  <label className="mb-2">
                    Dispatch Date<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    className="input-box"
                    placeholder="Dispatched on"
                    onChange={(date, dateString) => {
                      setDispatchDate(dateString);
                    }}
                    disabledDate={(current) => {
                      return current && (current > moment().endOf('day') || current < moment().subtract(1, 'week').startOf('day'));
                    }}
                  />
                </div>
                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div>
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={() => handleShow()}
                      >
                        View Items to Dispatch
                      </Button>
                    </div>

                    {/* view items before dispatch */}
                    <Offcanvas
                      placement="end"
                      show={show}
                      onHide={handleClose}
                      className="view-added-spares-modal"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Items to be Dispatched</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        {localItems.length === 0 ? (
                          <p>No items added yet!</p>
                        ) : (
                          <ul>
                            {localItems.map((item, index) => (
                              <li
                                key={index}
                                className="d-flex justify-content-between align-items-center mb-3"
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      item.Image || "path/to/default-image.jpg"
                                    }
                                    alt={item.Name}
                                    className="img-fluid"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <div>
                                    <p className="text-dark mb-0 fw-bold">
                                      {item.ItemName}
                                    </p>
                                    <span className="text-dark">
                                      Number of Cartons: {item.Quantity}
                                    </span>
                                  </div>
                                </div>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => handleRemoveItem(item.Item)}
                                >
                                  <MdDelete />
                                </Button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </Offcanvas.Body>
                      {localItems.length > 0 && (
                        <>
                          <div className="mt-4 d-grid send-spare-off-btn">
                            <p className="text-warning">Check all details carefully. You can’t change them after submission.</p>

                            <Button
                              size="lg"
                              className="border-0 default-btn"
                              onClick={() => dispatchItemsToCustomer(localItems)}
                            >
                              Dispatch Items
                            </Button>
                          </div>
                        </>
                      )}
                    </Offcanvas>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
