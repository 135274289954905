import React, { useEffect, useState } from "react";
import {
  BsFillHouseDoorFill,
  BsFillPersonVcardFill,
  BsBoxFill,
} from "react-icons/bs";
import { FaRectangleList } from "react-icons/fa6";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./SideNavbar.css";
import { Tooltip } from "antd";
export const StoreManagerSidebar = ({ handleCollapse }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const targetPath = "/spares-request-form"
  // const targetPath =
  //   windowWidth > 568 ? "/spares-request-form" : "/spare-request-list";
  return (
    <>
      <Menu>
        <Link to={"/dashboard"} onClick={() => handleCollapse()}>
          <MenuItem className="sidebar-menuitem">
            <Tooltip
              title="Dashboard"
              placement="topLeft"
              overlayClassName="custom-tooltip">
              <BsFillHouseDoorFill className="sidebar-icon" /> Dashboard
            </Tooltip>
          </MenuItem>
        </Link>
        <SubMenu
          label="Stock Management"
          className="sidebar-menuitem"
          icon={<FaRectangleList className="sidebar-icon" />}
        >
          <MenuItem>
            {" "}
            <Link to={"/item-stock"} onClick={() => handleCollapse()}>
              Item Stock Statement
            </Link>
          </MenuItem>
          <MenuItem>
            {" "}
            <Link to={targetPath}
              state={[]} onClick={() => handleCollapse()}>
              Add Spare
            </Link>
          </MenuItem>
          <MenuItem>
            {" "}
            <Link to={"/item-spares-stock"} onClick={() => handleCollapse()}>
              Stock Update
            </Link>
          </MenuItem>
        </SubMenu>


        <SubMenu
          label="Disptach Management"
          className="sidebar-menuitem"
          icon={<BsBoxFill className="sidebar-icon" />}
        >
          <MenuItem>
            {" "}
            <Link to={"/order-master"} onClick={() => handleCollapse()}>
              Dispatch Management
            </Link>
          </MenuItem>
          <MenuItem>
            {" "}
            <Link to={"/dispatched-orders"} onClick={() => handleCollapse()}>
              Dispatched Orders
            </Link>
          </MenuItem>
        </SubMenu>
        {/* <Link to={"/order-master"}>
          <MenuItem
            className="sidebar-menuitem"
            onClick={() => handleCollapse()}
          >
            <BsBoxFill className="sidebar-icon" /> Dispatch Management
          </MenuItem>
        </Link> */}
      </Menu>
    </>
  );
};
