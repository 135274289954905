import { Input, Switch } from "antd";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./CustomerManagement.css";

// For integrate company form using react UseForm
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import CustomerList from "./CustomerMasterList";

export default function CustomerMaster() {
  const [customerid, setCustomerid] = useState(null);
  const [status, setStatus] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  // Send save data
  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        customerid: customerid,
        customername: data.customername,
        customercontactperson: data.customercontactperson,
        customeraddress: data.customeraddress,
        customermobile: data.customermobile,
        customeremail: data.customeremail,
        customergstin: data.customergstin,
        customercity: data.customercity,
        customerstate: data.customerstate,
        customerpincode: data.customerpincode,
        status: status ? "Active" : "Inactive",
      },
      "AddCustomer"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("Customer registered sucessfully.");
          window.location.reload();
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };
  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };

  return (
    <div className="margin-left">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-0 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Add New Customer</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-lg-4 pb-3">
                  <label>
                    Customer Name <span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    // placeholder="Customer Name"
                    allowClear
                    className="input-box"
                    id="customername"
                    {...register("customername", {
                      required: "Customer name is required",
                    })}
                    onChange={handleChange("customername")}
                  />
                  {errors.customername && (
                    <p className="text-danger">{errors.customername.message}</p>
                  )}
                </div>

                <div className="col-lg-4 pb-3">
                  <label>
                    Mobile Number
                  </label>
                  <Input
                    size="large"
                    // placeholder="Mobile Number"
                    allowClear
                    className="input-box"
                    maxLength={10}
                    id="customermobile"
                    {...register("customermobile", {
                      pattern: {
                        value: /^\d{10}$/,
                        message: "customermobile must be 10 digits",
                      },
                    })}
                    onChange={handleChange("customermobile")}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <label>
                    Address <span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    // placeholder="Address"
                    allowClear
                    id="customeraddress"
                    className="input-box"
                    {...register("customeraddress", {
                      required: "Customer address is required",
                    })}
                    onChange={handleChange("customeraddress")}
                  />
                  {errors.customeraddress && (
                    <p className="text-danger">
                      {errors.customeraddress.message}
                    </p>
                  )}
                </div>
                {/* <div className="col-lg-4 pb-3 mt-3">
                  <Input
                    size="large"
                    placeholder="Email ID"
                    allowClear
                    className="input-box"
                    id="customeremail"
                    {...register("customeremail", {
                      required: "Email ID is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email format",
                      },
                    })}
                    onChange={handleChange("customeremail")}
                  />
                  {errors.customeremail && (
                    <p className="text-danger my-0 py-1">
                      {errors.customeremail.message}
                    </p>
                  )}
                </div> */}

                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={handleStatusChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div className="text-end w-100">
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save Customer
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* **** Customer List **** */}
        <CustomerList />
      </div>
    </div>
  );
}
