import { Input, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import GetApiCall from "../../GetApi";
import PostApiCall from "../../PostApi";
export default function ItemSparesStock() {
  const [spares, setSpares] = useState([]);
  const [sparesList, setSparesList] = useState([]);
  const [filteredItem, setFilteredItem] = useState([]);
  const [status, setStatus] = useState(true);
  const [warehouseList, setWarehouseList] = useState([]);
  const [lighterTypes, setLighterTypes] = useState([]);
  dayjs.extend(customParseFormat);
  const getSpareItems = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetSpareItemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setSpares(
              obj.data.filter((spare) => spare.FLD_Enabled_Flag == "Active")
            );
            setSparesList(
              obj.data.filter((spare) => spare.FLD_Enabled_Flag == "Active")
            );
          }
        })
    );
  };
  const getWarehouses = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetWarehouseMasterList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setWarehouseList(obj.data);
          }
        })
    );
  };
  // const getAvailableStocks = () => {
  //   PostApiCall.postRequest({ whereClause: "" }, "GetItemSpareStockList").then(
  //     (results) =>
  //       results.json().then((obj) => {
  //         if (results.status === 200 || results.status === 201) {
  //           setStocks(obj.data);
  //         }
  //       })
  //   );
  // };

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        stockId: null,
        itemid: data.spareid,
        warehouseitemquantity: data.quantity,
        warehoueid: data.warehouseid,
        stocktype: "Spare",
        status: status ? "Active" : "Inactive",
        numberofcartons: data.bagsCartons,
      },
      "AddWarehouseItemStock"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Stock added succesfully");
          reset({
            quantity: "",
            spareid: null,
          });
          // getAvailableStocks();
        } else {
          Notiflix.Notify.failure("Failed to add stock");
        }
      })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };
  const getLighterTypes = () => {
    GetApiCall.getRequest("GetLighterTypes").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setLighterTypes(obj.data);
          }
        })
    );
  };

  useEffect(() => {
    getSpareItems();
    // getAvailableStocks();
    getWarehouses();
    getLighterTypes();
  }, []);

  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Item Spares Stock</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-3 pb-3">
                  <label>
                    Warehouse<span className="text-danger">*</span>
                  </label>
                  <Select
                    size="large"
                    className="input-box"
                    name="warehouseid"
                    placeholder="Choose Warehouse"
                    {...register("warehouseid", {
                      required: "Warehouse is required",
                    })}
                    value={watch("warehouseid" || "")}
                    onChange={(value) => {
                      setValue("warehouseid", value);
                      clearErrors("warehouseid");
                    }}
                  >
                    {warehouseList
                      .filter((filter) => filter.FLD_Enabled_Flag == "Active")
                      .map((warehouse) => (
                        <Select.Option
                          key={warehouse.FLD_Warehouse_Id}
                          value={warehouse.FLD_Warehouse_Id}
                        >
                          {warehouse.FLD_Warehouse_Name}
                        </Select.Option>
                      ))}
                  </Select>

                  {errors.warehouseid && (
                    <p className="text-danger mb-0">
                      {errors.warehouseid.message}
                    </p>
                  )}
                </div>
                <div className="col-lg-3 pb-3">
                  <label>
                    Lighter Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    size="large"
                    className="input-box"
                    name="lightertype"
                    placeholder="Choose Lighter Type"
                    {...register("lightertype", {
                      required: "Lighter Type is required",
                    })}
                    value={watch("lightertype" || "")}
                    onChange={(value) => {
                      setValue("lightertype", value);
                      clearErrors("lightertype");
                      setSpares(
                        sparesList.filter((dat) => dat.FLD_Spare_Type == value)
                      );
                    }}
                  >
                    {lighterTypes
                      .filter((types) => types.FLD_Enabled_Flag === "Active")
                      .map((type) => (
                        <Select.Option
                          key={type?.FLD_Id}
                          value={type?.FLD_Lighter_Type}>
                          {type?.FLD_Lighter_Type}
                        </Select.Option>
                      ))}
                  </Select>

                  {errors.lightertype && (
                    <p className="text-danger mb-0">
                      {errors.lightertype.message}
                    </p>
                  )}
                </div>
                <div className="col-lg-3 pb-3">
                  <label>
                    Spare Item<span className="text-danger">*</span>
                  </label>
                  <Select
                    size="large"
                    className="input-box"
                    name="spareid"
                    placeholder="Choose Spare Item"
                    {...register("spareid", {
                      required: "Spare item is required",
                    })}
                    value={watch("spareid" || "")}
                    onChange={(value) => {
                      setFilteredItem(
                        spares.filter((dat) => dat.FLD_Item_Spares_Id == value)
                      );
                      setValue("spareid", value);
                      clearErrors("spareid");
                    }}
                  >
                    {spares.map((spare) => (
                      <Select.Option
                        key={spare.FLD_Item_Spares_Id}
                        value={spare.FLD_Item_Spares_Id}
                      >
                        {spare.FLD_Color === "No Colour"
                          ? `${spare.FLD_Item_Spare_Name}- ${spare.BrandName}`
                          : `${spare.FLD_Item_Spare_Name}- ${spare.BrandName} (${spare.FLD_Color})`}
                      </Select.Option>
                    ))}
                  </Select>

                  {errors.spareid && (
                    <p className="text-danger mb-0">{errors.spareid.message}</p>
                  )}
                </div>
                <div className="col-lg-3 pb-3">
                  <label>
                    No. of Bags / Cartons<span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    type="text"
                    allowClear
                    className="input-box"
                    value={watch("bagsCartons") || ""}
                    id="bagsCartons"
                    name="bagsCartons"
                    {...register("bagsCartons", {
                      required: "This field is required",
                    })}
                    onChange={handleChange("bagsCartons")}
                  />

                  {errors.bagsCartons && (
                    <p className="text-danger mb-0">
                      {errors.bagsCartons.message}
                    </p>
                  )}
                </div>
                <div className="col-lg-3 pb-3">
                  <label htmlFor="quantity">
                    Approximate No of Pieces<span className="text-danger">*</span>
                  </label>
                  <Input
                    size="large"
                    // placeholder="Quantity"
                    type="text"
                    allowClear
                    className="input-box"
                    value={watch("quantity") || ""}
                    id="quantity"
                    name="quantity"
                    {...register("quantity", {
                      required: "Pieces is required",
                    })}
                    onChange={handleChange("quantity")}
                  />
                  {errors.quantity && (
                    <p className="text-danger mb-0">
                      {errors.quantity.message}
                    </p>
                  )}
                </div>
                {filteredItem[0]?.FLD_Uom == "grams" && (
                  <div className="col-lg-4 pb-3">
                    <label htmlFor="totalcount">Approximate Weight</label>
                    <Input
                      size="large"
                      placeholder=""
                      allowClear
                      className="input-box"
                      disabled
                      value={`${filteredItem[0]?.FLD_Weight && watch("quantity")
                        ? (filteredItem[0]?.FLD_Weight * watch("quantity")) /
                        1000
                        : 0
                        } Kg`}
                    />
                  </div>
                )}
                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-end align-items-center">
                    {/* <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch checked={status} disabled />
                      <p className="mb-0">Active</p>
                    </div> */}

                    <div>
                      <Button
                        onClick={handleSubmit(onSubmit)}
                        className="default-btn border-0 mt-3"
                      >
                        Add Stock
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  Spare items stock listing */}
          {/* <div className="col-12 px-0">
            <div className="default-card default-card-border mt-2">
              <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
                <h4>List of Spare Stocks (25 Nos.)</h4>
                <div className="search-box w-50">
                  <Search
                    placeholder="Search Spare Stock"
                    allowClear
                    onSearch={onSearch}
                    className="w-100"
                  />
                </div>
              </div>
              <div className="spare-card">
                <div className="row">
                  <div className="col-lg-4 pb-3">
                    <label htmlFor="spareid" >Spare Item<span className="text-danger">*</span></label>
                    <Select
                      size="large"
                      className="input-box"
                      name="spareid"
                      id="spareid"
                      placeholder="Choose Spare Item"
                      {...register("spareid", {
                        required: "Spare item is required",
                      })}
                      onChange={(value) => {
                        setValue("spareid", value);
                        clearErrors("spareid");
                      }}
                    >
                      {spares.map((spare) => (
                        <Select.Option key={spare.spare_id} value={spare.FLD_Item_Spares_Id}>
                          {spare.FLD_Item_Spare_Name}
                        </Select.Option>
                      ))}
                    </Select>

                    {errors.spareid && <p className="text-danger">{errors.spareid.message}</p>}
                  </div>
                  <div className="col-lg-4 pb-3">
                    <lebl>Quantity</lebl>
                    <Input
                      size="large"
                      placeholder="Quantity"
                      allowClear
                      className="input-box"
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-12">
                    <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0">Inactive</p>
                        <Switch onChange={onChange} />
                        <p className="mb-0">Active</p>
                        <p className="mb-0 ms-3">
                          Last Edited - Vishnu (29/08/2024)
                        </p>
                      </div>

                      <div className="d-flex gap-3">
                        <Button className="default-btn border-0 mt-3">
                          Delete Spare Stock
                        </Button>
                        <Button className="default-btn border-0 mt-3">
                          Edit Spare Stock
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <ItemSparesStockList stockList={stocks} /> */}
      </div>
    </div>
  );
}
