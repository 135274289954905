import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  BsBoxFill,
  BsFillHouseDoorFill,
  BsFillPersonVcardFill,
  BsHouseGearFill, BsPersonFillGear
} from "react-icons/bs";
import { FaUsersCog } from "react-icons/fa";
import { FaFileCircleXmark, FaRectangleList } from "react-icons/fa6";
import { HiShoppingCart } from "react-icons/hi";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./SideNavbar.css";

export default function ManagerSideNvbar({ handleCollapse }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const targetPath = "/spares-request-form"
  // const targetPath =
  //   windowWidth > 568 ? "/spares-request-form" : "/spare-request-list";
  return (
    <Menu>
      <Link to={"/dashboard"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <Tooltip
            title="Dashboard"
            placement="topLeft"
            overlayClassName="custom-tooltip">
            <BsFillHouseDoorFill className="sidebar-icon" /> Dashboard
          </Tooltip>
        </MenuItem>
      </Link>
      <SubMenu
        label="Production Management"
        className="sidebar-menuitem"
        icon={
          // <Tooltip title="Production Management" placement="topLeft" overlayClassName="custom-tooltip">
          <BsHouseGearFill className="sidebar-icon" />
          // </Tooltip>
        }>
        <MenuItem>
          {" "}
          <Link to={"/create-job-card"} onClick={() => handleCollapse()}>
            Job Card
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/active-job-card-list"} onClick={() => handleCollapse()}>
            Active Job Cards
          </Link>
        </MenuItem>
      </SubMenu>
      <SubMenu
        label="Stock Management"
        className="sidebar-menuitem"
        icon={<FaRectangleList className="sidebar-icon" />}>
        <MenuItem>
          {" "}
          <Link to={"/item-stock"} onClick={() => handleCollapse()}>
            Item Stock Statement
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={targetPath}
            state={[]} onClick={() => handleCollapse()}>
            Add Spare
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/item-spares-stock"} onClick={() => handleCollapse()}>
            Stock Update
          </Link>
        </MenuItem>
      </SubMenu>
      <Link to={"/customer-master"}>
        <MenuItem className="sidebar-menuitem" onClick={() => handleCollapse()}>
          <Tooltip
            title="Customer Management"
            placement="topLeft"
            overlayClassName="custom-tooltip">
            <BsFillPersonVcardFill className="sidebar-icon fs-6" /> Customer
            Management
          </Tooltip>
        </MenuItem>
      </Link>

      <SubMenu
        label="Item Management"
        className="sidebar-menuitem"
        icon={<HiShoppingCart className="sidebar-icon" />}>
        <MenuItem>
          {" "}
          <Link to={"/item-spares-master"} onClick={() => handleCollapse()}>
            Item Spares Master
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/item-master"} onClick={() => handleCollapse()}>
            Item Master
          </Link>
        </MenuItem>
      </SubMenu>

      <SubMenu
        label="Master Management"
        className="sidebar-menuitem"
        icon={<FaUsersCog className="sidebar-icon" />}>
        <MenuItem>
          {" "}
          <Link to={"/company-master"} onClick={() => handleCollapse()}>
            Company Master
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/brand-master"} onClick={() => handleCollapse()}>
            Brand Master
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/store-master"} onClick={() => handleCollapse()}>
            Store Master
          </Link>
        </MenuItem>
        <MenuItem>
          {" "}
          <Link to={"/warehouse-master"} onClick={() => handleCollapse()}>
            Warehouse Master
          </Link>
        </MenuItem>
      </SubMenu>

      <Link to={"/staff-master"}>
        <MenuItem className="sidebar-menuitem" onClick={() => handleCollapse()}>
          <Tooltip
            title="Staff Management"
            placement="topLeft"
            overlayClassName="custom-tooltip">
            <BsPersonFillGear className="sidebar-icon" /> Staff Management
          </Tooltip>
        </MenuItem>
      </Link>

      {/* <Link to={"/reports"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <BiSolidReceipt className="sidebar-icon" /> Reports
        </MenuItem>
      </Link> */}
      <Link to={"/order-master"}>
        <MenuItem className="sidebar-menuitem" onClick={() => handleCollapse()}>
          <Tooltip
            title="Dispatch Management"
            placement="topLeft"
            overlayClassName="custom-tooltip">
            <BsBoxFill className="sidebar-icon" /> Dispatch Management
          </Tooltip>
        </MenuItem>
      </Link>
      <Link to={"/rejections"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <Tooltip
            title="Rejection Management"
            placement="topLeft"
            overlayClassName="custom-tooltip">
            <FaFileCircleXmark className="sidebar-icon" /> Rejection Management
          </Tooltip>
        </MenuItem>
      </Link>

      {/* <Link to={"/notification-master"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <IoNotifications className="sidebar-icon" /> Notification Management
        </MenuItem>
      </Link> */}
    </Menu>
  );
}
