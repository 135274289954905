import React, { useEffect, useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./TopHeader.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import PostApiCall from "../../PostApi";
import NewJobCard from "../../assets/img/topheader-icons/new-job-card.png";
import Completed from "../../assets/img/topheader-icons/completed.png";
import TotalRepairable from "../../assets/img/topheader-icons/total-repairable.png";
import DeadStock from "../../assets/img/topheader-icons/dead-stock.png";
import ItemsonAssembly from "../../assets/img/topheader-icons/items-on-assembly.png";
import AvailableCylinders from "../../assets/img/topheader-icons/available-cylinders.png";
import { IoIosStats } from "react-icons/io";
// import required modules
export const TopHeader = () => {
  const [completedJobCards, setCompletedJobCards] = useState([]);
  const [totalRejections, setTotalRejections] = useState([]);
  const [deadStock, setDeadStock] = useState([]);
  const [newJobCards, setNewJobCards] = useState([]);
  const [itemsOnAssembly, setItemsOnAssembly] = useState([]);
  const [availableCylinders, setAvailableCylinders] = useState([]);
  const [readyStocks, setReadyStocks] = useState([]);

  useEffect(() => {
    const date = new Date().getDate()
    console.log(date)
    PostApiCall.postRequest({}, "GetAdminDashChips")
      .then((result) => result.json())
      .then((obj) => {
        setCompletedJobCards(obj.data[0].CompletedJobCards !== null ? obj.data[0].CompletedJobCards : 0);
        setTotalRejections(obj.data[0].RepaiableRejections !== null ? obj.data[0].RepaiableRejections : 0);
        setDeadStock(obj.data[0].DeadStock !== null ? obj.data[0].DeadStock : 0);
        setNewJobCards(obj.data[0].NewJobCards !== null ? obj.data[0].NewJobCards : 0);
        setItemsOnAssembly(obj.data[0].DispatchFromAssembly !== null ? obj.data[0].DispatchFromAssembly : 0);
        setAvailableCylinders(obj.data[0].AvailableCylinders !== null ? obj.data[0].AvailableCylinders : 0);
        setReadyStocks(obj.data[0].ReadyGoods !== null ? obj.data[0].ReadyGoods : 0);
      });
  }, []);

  return (
    <>
      <div className="margin-left">
        <div className="container-fluid pt-5">
          <div className="row pt-4">
            <div className="col-12 mt-3 mt-lg-1 px-0">
              <div>
                <h4 className="top-badge"><IoIosStats /> Daily Stats</h4>
              </div>
              <Swiper
                slidesPerView={1.5}
                spaceBetween={15}
                loop={false}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 25,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper top-header-swiper"
              >
                <div className="top-header-box">
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={NewJobCard}
                          className="img-fluid"
                          alt="New Job Cards"
                        />
                      </div>
                      <div>
                        <span>New Job Cards</span>
                        <p>{newJobCards}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={Completed}
                          className="img-fluid"
                          alt="Completed"
                        />
                      </div>
                      <div>
                        <span>Completed</span>
                        <p>{completedJobCards}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={TotalRepairable}
                          className="img-fluid"
                          alt="Total Repairable"
                        />
                      </div>
                      <div>
                        <span>Total Repairable</span>
                        <p>{totalRejections}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={DeadStock}
                          className="img-fluid"
                          alt="Dead Stock"
                        />
                      </div>
                      <div>
                        <span>Dead Stock</span>
                        <p>{deadStock}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={ItemsonAssembly}
                          className="img-fluid"
                          alt="Items on Assembly"
                        />
                      </div>
                      <div>
                        <span>Items on Assembly</span>
                        <p>{itemsOnAssembly}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={AvailableCylinders}
                          className="img-fluid"
                          alt="Available Cylinders"
                        />
                      </div>
                      <div>
                        <span>Available Cylinders</span>
                        <p>{availableCylinders}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card top-header">
                      <div className="top-header-icon">
                        <img
                          src={AvailableCylinders}
                          className="img-fluid"
                          alt="Available Cylinders"
                        />
                      </div>
                      <div>
                        <span>Ready Goods</span>
                        <p>{readyStocks}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>
            {/* <div className="col-lg-3 mt-4 pe-lg-0">
              <div className="top-header client-logo">
                <img src={ClientLogo} className="img-fluid" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
