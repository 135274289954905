import { DatePicker, Input, Modal } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsBox2Fill } from "react-icons/bs";
import { FaBoxes, FaCalendarAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Assembly from "../../assets/img/icons/Assembly.png";
import Completed from "../../assets/img/icons/completed.png";
import FlameTest1 from "../../assets/img/icons/flametest-1.png";
import FlameTest2 from "../../assets/img/icons/flametest-2.png";
import GasFilling from "../../assets/img/icons/gas-filling.png";
import QualityCheck from "../../assets/img/icons/Quality-Check.png";
import TrayShifting from "../../assets/img/icons/Tray-Shifting.png";
import PostApiCall from "../../PostApi";
import "./SupervisorDashboard.css";
export default function SupervisorDashboard() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const [jobCardData, setJobCartData] = useState([]);
  const [dashboardChipData, setDashboardChipData] = useState([]);
  const [requestedSpare, setRequestedSpare] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    getJobCards();
    getDashboardChipCount();
  }, []);
  useEffect(() => {
    const getSpareRequests = () => {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          whereClause: `where FLD_Created_By = ${login[0].FLD_User_Id}`,
        },
        "GetSpareRequests"
      ).then((resultdes) => {
        resultdes.json().then((obj) => {
          try {
            setRequestedSpare(obj.data);
          } catch (error) {
            console.log(error);
            Notiflix.Loading.remove();
          }
          Notiflix.Loading.remove();
        });
      });
    };

    getSpareRequests();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const targetPath =
    windowWidth > 568 ? "/spares-request-form" : "/spare-request-list";

  dayjs.extend(customParseFormat);
  const getJobCards = () => {
    try {
      PostApiCall.postRequest(
        {
          wherecaluse: "",
        },
        "GetActiveJobCards"
      ).then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setJobCartData(obj.data);
          } else {
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getDashboardChipCount = () => {
    try {
      PostApiCall.postRequest({}, "GetDashboardCount").then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setDashboardChipData(obj.data);
          } else {
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getStageCount = (stageName) => {
    const stageCount = dashboardChipData
      .filter((stage) => stage.FLD_Active_Stage === stageName)
      .reduce((total, stage) => total + stage.stage_count, 0);

    return stageCount ? stageCount : 0;
  };
  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    console.log(typeof data.date);
    PostApiCall.postRequest(
      {
        cylindersin: data.cylinderin,
        cylindersout: data.cylinderout,
        date: data.date,
        createdby: login[0].FLD_User_Id,
      },
      "UpdateGasBankDetails"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          reset({
            cylinderin: "",
            cylinderout: "",
            date: null,
          });
          setIsModalOpen(false);
          Notiflix.Notify.success("");
        } else {
          Notiflix.Notify.failure("User registration failed!");
        }
      })
    );
  };

  return (
    <>
      <div className="margin-left blank-section job-card-page supervisor-dashboard">
        <div className="container-fluid pt-4 pb-2">
          <div className="row pt-4 mt-4 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card default-card-border">
                <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
                  <h4>Job Cards</h4>
                </div>
                <div className="jobcard-count-box">
                  <div className="jobcard-count-card">
                    <img
                      src={GasFilling}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Gas Filling")}</p>
                    <span>Gas Filling</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={FlameTest1}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Flame Testing 1")} </p>
                    <span>Flame Test 1</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={FlameTest2}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Flame Testing 2")}</p>
                    <span>Flame Test 2</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={Assembly}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Assembly")}</p>
                    <span>Assembly</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={QualityCheck}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Quality Check")}</p>
                    <span>Quality Check</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={TrayShifting}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Tray Shifting")}</p>
                    <span>Tray Shifting</span>
                  </div>
                  <div className="jobcard-count-card">
                    <img
                      src={Completed}
                      className="img-fluid"
                      alt="Gas Filling"
                    />
                    <p>{getStageCount("Completed")}</p>
                    <span>Completed</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card">
                <div className="card-heading">
                  <h4>Active Job Cards</h4>
                </div>
                <div className="job-card-main-box tray-shifting-job-card-box tray-shifting-active-card-box">
                  {jobCardData
                    .filter((jobCard) => jobCard.active_stage !== "Completed")
                    .map((jobcard, jobCardIndex) => {
                      return (
                        <div
                          key={jobCardIndex}
                          className="job-card-box"
                          onClick={() => navigate("/active-job-card-list")}
                        >
                          <div className="box border-0 justify-content-between mb-2">
                            <div className="d-flex align-items-center">
                              <p className="card-number">
                                <img
                                  alt=""
                                  src={jobcard.item_image}
                                  className="img-fluid"
                                />{" "}
                                {jobcard.FLD_Job_Card_Number}
                              </p>
                            </div>
                          </div>
                          <div className="box">
                            <span>Total Quantity : </span>
                            <p>{jobcard.FLD_Item_Qty}</p>
                          </div>
                          <div className="box">
                            <span>Current Quantity : </span>
                            <p>{jobcard.FLD_Current_Quantity}</p>
                          </div>
                          <div className="box">
                            <span>Current Stage : </span>
                            <p>{jobcard.active_stage}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card">
                <div className="card-heading d-flex justify-content-between align-items-lg-center">
                  <h4>Spare Request</h4>
                  {requestedSpare.length ? (
                    <div>
                      <Link
                        to={targetPath}
                        state={requestedSpare}
                        className="default-btn"
                      >
                        View All
                      </Link>
                    </div>
                  ) : (
                    <div className="text-center mt-1">
                      <Link
                        to="/spares-request-form"
                        state={[]}
                        className="default-btn"
                      >
                        Request a Spare
                      </Link>
                    </div>
                  )}
                </div>
                <div className="job-card-main-box spare-card-list">
                  {requestedSpare
                    .sort(
                      (a, b) =>
                        b.FLD_Spares_Request_Id - a.FLD_Spares_Request_Id
                    )
                    .slice(0, 4)
                    .map((spares, index) => {
                      return (
                        <div key={index} className="job-card-box">
                          <div className="box">
                            <BsBox2Fill />
                            <p>{spares.items_name}</p>
                          </div>
                          <div className="box">
                            <FaBoxes />
                            <p>{spares.items_total_count}</p>
                          </div>
                          <div className="box">
                            <FaCalendarAlt />
                            <p>{spares.FLD_Spares_Stock_Request_Date}</p>
                          </div>
                          <div className={
                            // eslint-disable-next-line
                            spares.FLD_Enabled_Flag == "Dispatched" ? "status-box-dispatched" : "status-box"}>
                            <p>{spares.FLD_Enabled_Flag}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {requestedSpare.length ? (
                  <div className="text-center mt-3">
                    <Link
                      to="/spares-request-form"
                      state={[]}
                      className="default-btn"
                    >
                      Request a Spare
                    </Link>
                  </div>
                ) : (
                  <p className="text-center">No Requests Found</p>
                )}
                {/* <CreateBatch /> */}

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={
          <>
            <h4>Update Cylinders Stock</h4>
          </>
        }
        open={isModalOpen}
        centered
        width={550}
        footer={null}
        // onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        zIndex={1000}
      >
        <div className="row">
          <p className="mb-3">
            Fields marked with <span className="text-danger">*</span> are
            mandatory.
          </p>
          <div className="col-md-4 mb-2 mb-lg-0">
            <label for="companyName">
              Cylinders In <span className="text-danger">*</span>
            </label>
            <Input
              size="large"
              allowClear
              className="input-box"
              name="cylinderin"
              id="cylinderin"
              {...register("cylinderin", {
                required: "This field is mandatory",
              })}
              onChange={handleChange("cylinderin")}
            />
            {errors.cylinderin && (
              <p className="text-danger">{errors.cylinderin.message}</p>
            )}
          </div>
          <div className="col-md-4 mb-2 mb-lg-0">
            <label for="companyName">
              Cylinders Out <span className="text-danger">*</span>
            </label>
            <Input
              size="large"
              allowClear
              className="input-box"
              name="cylinderout"
              id="cylinderout"
              {...register("cylinderout", {
                required: "This field is mandatory",
              })}
              onChange={handleChange("cylinderout")}
            />
            {errors.cylinderout && (
              <p className="text-danger">{errors.cylinderout.message}</p>
            )}
          </div>
          <div className="col-md-4">
            <label for="companyName">
              Date <span className="text-danger">*</span>
            </label>
            <DatePicker
              size="large"
              placeholder="Choose a Date"
              allowClear
              className="input-box w-100"
              name="Date"
              id="date"
              format={"DD-MM-YYYY"}
              defaultValue={dayjs}
              {...register("date", {
                required: "This field is mandatory",
              })}
              onChange={(date, dateString) => {
                setValue("date", dateString);
                clearErrors("date");
              }}
            />
            {errors.date && (
              <p className="text-danger">{errors.date.message}</p>
            )}
          </div>
        </div>
        <div className="text-end">
          <span
            className="default-btn border-0 mt-3 d-inline-block"
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </span>
        </div>
      </Modal>
    </>
  );
}
