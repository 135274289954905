import { Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

// For integrate company form using react UseForm
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import StoreMasterList from "./StoreMasterList";

export default function StoreMaster() {
  const [storeid, setStoreid] = useState(null);
  const [status, setStatus] = useState(false);
  const [storeList, setStoreList] = useState([])
  useEffect(() => {
    getStoreList()
  }, []);
  const getStoreList = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetStoreList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setStoreList(obj.data);
          }
        })
    );
  }

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        storeid: storeid,
        storename: data.storename,
        status: status ? "Active" : "Inactive",
      },
      "AddStore"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Store registered sucessfully.");
          reset({
            storename: "",
          })
          setStatus(false)
          getStoreList();
        } else {
          Notiflix.Notify.failure("Store registration failed!");
        }
      })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };

  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-0 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Add New Store</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-12 pb-3">
                  <label htmlFor="storename">Store Name<span className="text-danger">*</span></label>
                  <Input
                    size="large"
                    // placeholder="Store Name"
                    allowClear
                    className="input-box"
                    value={watch("storename") || ""}
                    id="storename"
                    {...register("storename", {
                      required: "storename is required",
                    })}
                    onChange={handleChange("storename")}
                  />
                  {errors.storename && (
                    <p className="text-danger">{errors.storename.message}</p>
                  )}
                </div>
                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={handleStatusChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save Store
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Store Master List */}
        <StoreMasterList storeList={storeList} getStoreList={getStoreList} />
      </div>
    </div>
  );
}
