import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, message, Select, Switch, Upload } from "antd";
import Search from "antd/es/input/Search";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./ItemManagement.css";

// For integrate item form using react UseForm
import moment from "moment";
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import { store } from "../../context/Store";
import GetApiCall from "../../GetApi";
import PostApiCall from "../../PostApi";

// For get uploaded image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function ItemSparesMaster({
  getSpareItems,
  itemSpareList,
  colors,
}) {
  // const [itemSpareList, setItemSpareList] = useState([]);
  const [editingSpareItemId, setEditingSpareItemId] = useState(null);
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const { brandUniqId } = useContext(store);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [lighterTypes, setLighterTypes] = useState([]);

  // For image
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const randomNumber = Math.floor(Math.floor(100000 + Math.random() * 900000));

  // Initilize UseForm
  const {
    // register,
    // setValue,
    // clearErrors,
    // handleSubmit,
    formState: { errors },
  } = useForm();

  // For edit forms
  const handleEditItem = (itemSpareId, action = "Edit") => {
    const itemsUpdate = itemSpareList.find(
      (spareitems) => spareitems.FLD_Item_Spares_Id === itemSpareId
    );
    setFormData({
      itemsparename: itemsUpdate.FLD_Item_Spare_Name,
      sku: itemsUpdate.FLD_Sku,
      brandid: itemsUpdate.FLD_Brand_Id,
      image: itemsUpdate.FLD_Item_Spare_Image,
      spareitemtype: itemsUpdate.FLD_Spare_Type,
      unit: itemsUpdate.FLD_Unit,
      weight: itemsUpdate.FLD_Weight,
      stockinhand: itemsUpdate.FLD_Stock_In_Hand,
      color: itemsUpdate.FLD_Color,
      storename: itemsUpdate.fld_store_id,
      // unit: itemsUpdate.FLD_Uom,
      status: itemsUpdate.FLD_Enabled_Flag === "Active",
    });
    if (action === "Delete") {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure you want to delete this item spare?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              Notiflix.Loading.dots("");
              handleUpdateItem(itemSpareId, itemsUpdate, "Delete");
            },
          },
          {
            label: "No",
            onClick: async () => {
              setEditingSpareItemId(null);
            },
          },
        ],
        closeOnClickOutside: false,
      });
    }
    setEditingSpareItemId(itemSpareId);
  };
  const getLighterTypes = () => {
    GetApiCall.getRequest("GetLighterTypes").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setLighterTypes(obj.data);
          }
        })
    );
  };

  const handleUpdateItem = (
    itemSpareId,
    itemsUpdate = "",
    action = "Update"
  ) => {
    Notiflix.Loading.dots("");
    const updatedSparePayload =
      // eslint-disable-next-line
      action == "Delete"
        ? {
          itemspareid: itemSpareId,
          itemsparename: itemsUpdate?.FLD_Item_Spare_Name,
          brandid: itemsUpdate?.FLD_Brand_Id,
          image:
            imageUrl === "" ? itemsUpdate?.FLD_Item_Spare_Image : imageUrl,
          sku: itemsUpdate?.FLD_Sku,
          spareitemtype: itemsUpdate?.FLD_Spare_Type,
          unit: itemsUpdate?.FLD_Unit,
          weight: itemsUpdate?.FLD_Weight,
          stockinhand: 1, // hard coded
          color: itemsUpdate?.FLD_Color,
          storename: itemsUpdate?.fld_store_id,
          measureunit: itemsUpdate?.FLD_Uom,
          status: "Deleted",
        }
        : {
          itemspareid: itemSpareId,
          itemsparename: formData?.itemsparename,
          brandid: formData?.brandid,
          image: imageUrl === "" ? formData?.image : imageUrl,
          sku: formData?.sku,
          spareitemtype: formData?.spareitemtype,
          unit: formData?.unit,
          weight: formData?.weight,
          stockinhand: 1, // hard coded
          color: formData?.color,
          storename: null,
          measureunit: formData?.unit,
          status: formData?.status ? "Active" : "Inactive",
        };

    PostApiCall.postRequest(updatedSparePayload, "AddSpareitem").then(
      (results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            Notiflix.Notify.success("Item updated successfully.");
            setEditingSpareItemId(null);
            getSpareItems();
          } else {
            Notiflix.Notify.failure("Item update failed!");
            Notiflix.Loading.remove();
          }
        })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // For edit and update status
  const handleToggleChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      status: checked,
    }));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const filteredSpareItemList = itemSpareList
    ?.filter((itemspare) =>
      itemspare?.FLD_Item_Spare_Name?.toLowerCase().includes(
        searchValue.toLowerCase()
      )
    )
    ?.filter(
      (itemspare) =>
        // eslint-disable-next-line
        selectedBrand === null || itemspare.FLD_Brand_Id == selectedBrand
    )
    ?.filter(
      (itemspare) =>
        // eslint-disable-next-line
        selectedType === null || itemspare.FLD_Spare_Type?.toLowerCase() == selectedType.toLowerCase()
    );

  // Handel image on server
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}>
        Upload Item Image
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "itemSpareMasterImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setImageUrl("");
  }, [setEditingSpareItemId]);
  useEffect(() => {
    getLighterTypes();
  }, [])

  return (
    <div className="row pt-0 mt-0 mb-4 px-0">
      <div className="col-12 px-0">
        <div className="default-card default-card-border mt-2">
          <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
            <h4>
              List of Spares (
              {
                filteredSpareItemList?.filter(
                  (item) => item.FLD_Enabled_Flag !== "Deleted"
                )?.length
              }{" "}
              Nos.)
            </h4>
            <div className="d-flex w-lg-75 justify-content-end gap-lg-3 mt-2 mt-lg-0 align-items-end flex-wrap">
              <div className="w-50 d-flex align-items-lg-center justify-content-lg-end flex-column flex-lg-row search-box bg-transparent rounded-0 pe-lg-1 pb-0 px-0">
                <h5 className="mb-1 mb-lg-0 me-lg-3">Filter By</h5>
                <div className="d-flex gap-3">
                  <Select
                    value={selectedBrand}
                    placeholder="Choose Brand"
                    onChange={(value) => setSelectedBrand(value)}
                    // onChange={handleFilterByBrand}
                    className={"store-stock-select warehouse-select mt-1 mt-lg-0 w-50"}>
                    <Select.Option key="all" value={null}>
                      All Brands
                    </Select.Option>
                    {brandUniqId
                      .filter((brands) => brands.FLD_Enabled_Flag === "Active")
                      .map((brand) => (
                        <Select.Option
                          key={brand?.FLD_Brand_Id}
                          value={brand?.FLD_Brand_Id}>
                          {brand?.FLD_Brand_Name}
                        </Select.Option>
                      ))}
                  </Select>
                  <Select
                    value={selectedType}
                    placeholder="Choose Brand"
                    onChange={(value) => setSelectedType(value)}
                    // onChange={handleFilterByType}
                    className={"store-stock-select warehouse-select mt-1 mt-lg-0 w-50"}
                  >
                    <Select.Option key="null" value={null}>
                      All Type
                    </Select.Option>
                    {lighterTypes
                      .filter((types) => types.FLD_Enabled_Flag === "Active")
                      .map((type) => (
                        <Select.Option
                          key={type?.FLD_Id}
                          value={type?.FLD_Lighter_Type}>
                          {type?.FLD_Lighter_Type}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="search-box">
                <Search
                  placeholder="Search Spares"
                  allowClear
                  className="w-100"
                  onSearch={onSearch}
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
              </div>
            </div>
          </div>
          {filteredSpareItemList?.length > 0 ? (
            filteredSpareItemList
              ?.filter((spare) => spare.FLD_Enabled_Flag !== "Deleted")
              .map((item, index) => (
                <div key={index} className="spare-card">
                  <div className="row">
                    <div className="col-lg-2 pb-3">
                      <Upload
                        disabled={
                          editingSpareItemId !== item.FLD_Item_Spares_Id
                        }
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader w-100"
                        showUploadList={false}
                        customRequest={onaction}
                        beforeUpload={beforeUpload}>
                        {editingSpareItemId !== item.FLD_Item_Spares_Id ? (
                          <img
                            src={item.FLD_Item_Spare_Image}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : item.FLD_Item_Spare_Image !== "" &&
                          imageUrl === "" ? (
                          <>
                            {
                              <img
                                src={item.FLD_Item_Spare_Image}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                }}
                              />
                            }
                          </>
                        ) : (
                          <>
                            {imageUrl === "" ? (
                              uploadButton
                            ) : (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                }}
                              />
                            )}
                          </>
                        )}
                      </Upload>
                    </div>
                    <div className="col-lg-10">
                      <div className="row">
                        <div className="col-lg-4 pb-3">
                          <label>Item SKU</label>
                          <Input
                            size="large"
                            placeholder={item.FLD_Sku ? "Item SKU" : "N/A"}
                            allowClear
                            className="input-box"
                            name="sku"
                            value={editingSpareItemId === item.FLD_Item_Spares_Id ? formData.sku : item.FLD_Sku}
                            disabled={
                              editingSpareItemId !== item.FLD_Item_Spares_Id
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-4 pb-3">
                          <label htmlFor="spareitemtype">
                            Spare Type <span className="text-danger">*</span>
                          </label>
                          <Select
                            size="large"
                            className="input-box"
                            placeholder="Spare Type"
                            name="spareitemtype"
                            // id="spareitemtype"
                            value={editingSpareItemId === item.FLD_Item_Spares_Id ? formData.spareitemtype : item.FLD_Spare_Type}
                            disabled={
                              editingSpareItemId !== item.FLD_Item_Spares_Id
                            }
                            onChange={(value) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                spareitemtype: value,
                              }));
                            }}
                          >
                            {lighterTypes
                              .filter((types) => types.FLD_Enabled_Flag === "Active")
                              .map((type) => (
                                <Select.Option
                                  key={type?.FLD_Id}
                                  value={type?.FLD_Lighter_Type}>
                                  {type?.FLD_Lighter_Type}
                                </Select.Option>
                              ))}
                          </Select>
                        </div>
                        {/* <div className="col-lg-4 pb-3">
                        <label htmlFor="storename">
                          Store Name <span className="text-danger">*</span>
                        </label>
                        <Select
                          size="large"
                          className="input-box"
                          name="storename"
                          disabled={
                            editingSpareItemId !== item.FLD_Item_Spares_Id
                          }
                          defaultValue={item?.fld_store ? item.fld_store : ""}
                          placeholder="Store Name"
                          onChange={(value) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              storename: value,
                            }));
                          }}>
                          {storesData.map((store) => (
                            <Select.Option
                              key={store.FLD_Store_Id}
                              value={store.FLD_Store_Id}>
                              {store.FLD_Store_Name}
                            </Select.Option>
                          ))}
                        </Select>
                      </div> */}

                        <div className="col-lg-4 pb-3">
                          <label htmlFor="brand">
                            Brand Name <span className="text-danger">*</span>
                          </label>
                          <Select
                            size="large"
                            className="input-box"
                            placeholder="Brand"
                            name="brandid"
                            // id="brand"
                            value={editingSpareItemId === item.FLD_Item_Spares_Id ? formData.brandid : item.FLD_Brand_Id}
                            disabled={
                              editingSpareItemId !== item.FLD_Item_Spares_Id
                            }
                            onChange={(value) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                brandid: value,
                              }));
                            }}
                            options={[
                              ...(brandUniqId?.map((brand) => ({
                                value: brand?.FLD_Brand_Id,
                                label: brand?.FLD_Brand_Name,
                              })) || []),
                            ]}
                          />
                        </div>
                        <div className="col-lg-4 pb-3">
                          <label htmlFor="itemsparename">
                            Spare Name <span className="text-danger">*</span>
                          </label>
                          <Input
                            size="large"
                            placeholder="Spare Name"
                            allowClear
                            // id="itemsparename"
                            className="input-box"
                            name="itemsparename"
                            value={editingSpareItemId === item.FLD_Item_Spares_Id ? formData.itemsparename : item.FLD_Item_Spare_Name}
                            disabled={
                              editingSpareItemId !== item.FLD_Item_Spares_Id
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-4 pb-3">
                          <label htmlFor="dept">
                            Spare Color<span className="text-danger">*</span>
                          </label>
                          <Select
                            size="large"
                            className="input-box"
                            id="color"
                            name="color"
                            placeholder="Spare Color"
                            value={editingSpareItemId === item.FLD_Item_Spares_Id ? formData.color : item.FLD_Color}
                            disabled={
                              editingSpareItemId !== item.FLD_Item_Spares_Id
                            }
                            onChange={(value) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                color: value,
                              }));
                            }}>
                            {colors.map((color) => (
                              <Select.Option
                                key={color.FLD_Color}
                                value={color.FLD_Color}>
                                {color.FLD_Color}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>

                        <div className="col-lg-4 pb-3">
                          <label htmlFor="weight">
                            Weight Per Piece{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Input
                            size="large"
                            placeholder="Weight Per Piece"
                            allowClear
                            className="input-box"
                            name="weight"
                            value={editingSpareItemId === item.FLD_Item_Spares_Id ? formData.weight : item.FLD_Weight}
                            disabled={
                              editingSpareItemId !== item.FLD_Item_Spares_Id
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-4 pb-3">
                          <label htmlFor="unit">
                            Unit of Measurement{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            size="large"
                            className="input-box"
                            // id="unit"
                            placeholder="Unit of Measurement"
                            value={editingSpareItemId === item.FLD_Item_Spares_Id ? formData.unit : item.FLD_Uom ? item.FLD_Uom : ""}
                            disabled={
                              editingSpareItemId !== item.FLD_Item_Spares_Id
                            }
                            onChange={(value) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                unit: value,
                              }));
                            }}
                            options={[
                              { value: "grams", label: "Grams (g)" },
                              { value: "kilograms", label: "Kilograms (kg)" },
                            ]}
                          />
                          {errors.unit && (
                            <p className="text-danger">{errors.unit.message}</p>
                          )}
                        </div>
                        {/* <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Stock In Hand"
                          allowClear
                          className="input-box"
                          name="stockinhand"
                          defaultValue={item.FLD_Stock_In_Hand}
                          disabled={
                            editingSpareItemId !== item.FLD_Item_Spares_Id
                          }
                          onChange={handleInputChange}
                        />
                      </div> */}

                        {/* <div className="col-lg-4 pb-3">
                        <label htmlFor="totalcount">
                          Approximate No. of Pieces
                        </label>
                        <Input
                          size="large"
                          placeholder="Approximate No. of Pieces"
                          allowClear
                          className="input-box"
                          disabled
                          value={"Approximate No. of Pieces (123456)"}
                        />
                      </div> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Inactive</p>
                          <Switch
                            checked={
                              editingSpareItemId === item.FLD_Item_Spares_Id
                                ? formData.status
                                : item.FLD_Enabled_Flag === "Active"
                            }
                            disabled={
                              editingSpareItemId !== item.FLD_Item_Spares_Id
                            }
                            onChange={handleToggleChange}
                          />
                          <p className="mb-0">Active</p>
                          <p className="mb-0 ms-3">
                            Last Edited -
                            {item.FLD_Updated_on && (
                              <>
                                {moment(item.FLD_Updated_on).format(
                                  "DD-MM-YYYY"
                                )}
                              </>
                            )}
                          </p>
                        </div>

                        <div className="d-flex gap-3">
                          <Button
                            className="default-btn border-0 mt-3"
                            onClick={() =>
                              handleEditItem(item.FLD_Item_Spares_Id, "Delete")
                            }>
                            Delete Spare
                          </Button>
                          <Button
                            className="default-btn border-0 mt-3"
                            onClick={() =>
                              editingSpareItemId === item.FLD_Item_Spares_Id
                                ? handleUpdateItem(item.FLD_Item_Spares_Id)
                                : handleEditItem(item.FLD_Item_Spares_Id)
                            }>
                            {editingSpareItemId === item.FLD_Item_Spares_Id
                              ? "Update Spare"
                              : "Edit Spare"}
                          </Button>

                          {editingSpareItemId === item.FLD_Item_Spares_Id && (
                            <Button
                              className="ml-2 default-btn border-0 mt-3"
                              onClick={() => setEditingSpareItemId(null)}>
                              Cancel
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <p>No Data Available</p>
          )}
        </div>
      </div>
    </div>
  );
}
